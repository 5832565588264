import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import "./style.scss"; // Import your custom CSS file

interface Option {
  label: string;
  value: number;
}

interface SearchableSelectProps {
  options: Option[];
  onChange: (e: string | number) => void;
  selected?: string;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options,
  onChange,
  selected,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);

  const selectRef = useRef<HTMLDivElement>(null);

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    setSearchTerm(option.label);
    setFilteredOptions(options);
    setIsOptionsVisible(false);
    onChange(option.value);
    console.log("component foi");
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLowerCase();
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm)
    );
    setFilteredOptions(filtered);
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
    setFilteredOptions(options);
    if (selected) {
      setSearchTerm(selected);
    }
  }, [options]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOptionsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="searchable-select" ref={selectRef}>
      <input
        type="text"
        placeholder="Search..."
        onChange={handleSearch}
        value={searchTerm}
        className="form-control"
        onFocus={() => setIsOptionsVisible(true)}
      />
      {isOptionsVisible && (
        <div
          className="options-container"
          onBlur={() => setIsOptionsVisible(false)}
        >
          {filteredOptions.map((option) => (
            <div
              key={option.value}
              className={`option ${
                selectedOption?.value === option.value ? "selected" : ""
              }`}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchableSelect;
