import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import ProductList from "./pages/ProductList";
import Login from "./pages/Login";
import RegisterCustomer from "./pages/RegisterCustomer";
import CustomerList from "./pages/CustomerList";
import PreBudgetProductList from "./pages/PreBudgetProductList";
import BudgetList from "./pages/BudgetList";
import RegisterIcms from "./pages/RegisterIcms";
import IcmsList from "./pages/IcmsList";
import FeeList from "./pages/FeeList";
import RegisterFee from "./pages/RegisterFee";
import Pricing from "./pages/Pricing";
import RegisterPreProcess from "./pages/RegisterPreProcess";
import PreProcessList from "./pages/PreProcessList";

import RegisterMaterial from "./pages/RegisterMaterial";
import MaterialList from "./pages/MaterialList";
import { useEffect } from "react";
import api from "./services/api";
import tokenService from "./services/TokenService";
import axios from "axios";
import FirmList from "./pages/FirmList";
import UserList from "./pages/UserList";

import { AuthProvider } from "./context/auth";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import AdminRoute from "./components/adminRoute/AdminRoute";
import FilePage from "./pages/filePage";

function App() {
  useEffect(() => {
    api.interceptors.request.use(async (config: any) => {
      if (config.url != "/user/login") {
        let token = window.sessionStorage.getItem("hash");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
      }
      return config;
    });

    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          const originalReq = err.config;
          // Inverter o if
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config._retry &&
            !err.config.url.endsWith("login")
          ) {
            originalReq._retry = true;
            let token = window.sessionStorage.getItem("hash");
            return tokenService
              .refresh({ oldtoken: token })
              .then((res) => {
                console.clear();
                // console.log(err.config.url);
                if (res.data.access_token) {
                  window.sessionStorage.setItem("hash", res.data.access_token);
                }
                originalReq.headers[
                  "Authorization"
                ] = `Bearer ${res.data.access_token}`;
                const req = axios(originalReq);
                resolve(req);
              })
              .catch((error) => console.log(error));
          } else {
            reject(err);
          }
        });
      }
    );
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<AppLayout />}>
            <Route
              index
              path="/customer-list"
              element={<PrivateRoute element={<CustomerList />} />}
            />
            <Route
              path="/product-list"
              element={<PrivateRoute element={<ProductList />} />}
            />
            <Route
              path="/pre-budget"
              element={<PrivateRoute element={<PreBudgetProductList />} />}
            />
            <Route
              path="/register-customer"
              element={<AdminRoute element={<RegisterCustomer />} />}
            />
            <Route
              path="/budget-list"
              element={<PrivateRoute element={<BudgetList />} />}
            />
            <Route
              path="/register-icms"
              element={<AdminRoute element={<RegisterIcms />} />}
            />

            <Route
              path="/register-fee"
              element={<AdminRoute element={<RegisterFee />} />}
            />

            <Route
              path="/pre-register-process"
              element={<AdminRoute element={<RegisterPreProcess />} />}
            />
            <Route
              path="/icms-list"
              element={<AdminRoute element={<IcmsList />} />}
            />
            <Route
              path="/fee-list"
              element={<AdminRoute element={<FeeList />} />}
            />

            <Route
              path="/list-pre-process"
              element={<AdminRoute element={<PreProcessList />} />}
            />
            <Route
              path="/pricing"
              element={<PrivateRoute element={<Pricing />} />}
            />

            <Route
              path="/register-material"
              element={<AdminRoute element={<RegisterMaterial />} />}
            />
            <Route
              path="/list-material"
              element={<AdminRoute element={<MaterialList />} />}
            />
            <Route
              path="/list-firms"
              element={<PrivateRoute element={<FirmList />} />}
            />
            <Route
              path="/generate-file"
              element={<PrivateRoute element={<FilePage />} />}
            />
            <Route
              path="/list-users"
              element={<AdminRoute element={<UserList />} />}
            />
          </Route>
          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
