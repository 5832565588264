import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss"
import { Button, Modal, Form, FormGroup } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import IPreProcess from "../../interfaces/IPreProcess";
import IMaterial from "../../interfaces/IMaterial";
import materialService from "../../services/MaterialService";
import { NumericFormat } from "react-number-format";


const RegisterMaterial = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation()
    const [process, setProcess] = useState<IPreProcess>(location.state?.process)
    const [material, setMaterial] = useState<IMaterial>(location.state?.material)

    /** variavies que irão usadas para enviar o post  */
    const [description, setDescription] = useState<string>("")
    const [standard, setStandard] = useState<string>("")
    const [units, setUnits] = useState<string>("")
    const [cost, setCost] = useState<number>(0)
    const [aliquota, setAliquota] = useState<number>(0)

    const [setup, setSetup] = useState<number>()
    const [costHour, setCostHour] = useState<number>()

    const navigate = useNavigate();

    const handleClick = (e: React.FormEvent) => {
        e.preventDefault();
        let data = {
            description: description,
            units_of_measurement: units,
            cost: cost,
            aliquota: aliquota,
        }
        if (material) {
                console.log(material)
            materialService.update(data, material.id)
                .then((response) => {
                    navigate("/list-material");
                })
        } else {
            
            materialService.create(data)
                .then((response) => {
                    navigate("/list-material");
                })
        }
    }

    const handleCancel = (e: React.FormEvent) => {
        navigate("/list-material")
    }

    useEffect(() => {
        if (material) {

            setDescription(material.description);
            setStandard(material.standard);
            setUnits(material.units_of_measurement);
            setCost(material.cost);
            setAliquota(material.aliquota)
        }
    }, [])

    
    let select:any;

    if (material) 
    {
        select =   
        <Form.Select id="untis" onChange={e => setUnits(e.target.value)}>
            <option selected value={units}>{units}</option>
            <option value="un">un</option>
            <option value="kg">kg</option>
            <option value="un">m2</option>
            {/* <option value="g">g</option>
            <option value="mg">mg</option>
            <option value="µg">µg</option>
            <option value="ng">ng</option> */}
        </Form.Select>
      } 
      else 
      {
        select =   
        <Form.Select id="untis" onChange={e => setUnits(e.target.value)}>
            <option value="">selecione a unidade de medida</option>
            <option value="un">un</option>
            <option value="kg">kg</option>
            <option value="un">m2</option>
            {/* <option value="g">g</option>
            <option value="mg">mg</option>
            <option value="µg">µg</option>
            <option value="ng">ng</option> */}
        </Form.Select>
      }

    return (
        <div className="container-fluid container-main">
            <div>
                <h1>Atualizar/cadastrar Matéria-Prima</h1>
            </div>
            <div className="div-form">
                <Form>
                    <Form.Group className="mb-5">
                        <Form.Label htmlFor="inputNameCustomer">Descrição</Form.Label>
                        {material
                            ? <Form.Control type="text" placeholder="descrição do material" value={description} onChange={(e) => setDescription(e.target.value)} />
                            : <Form.Control type="text" placeholder="descrição do material" onChange={(e) => setDescription(e.target.value)} />
                        }
                    </Form.Group>

                    <Form.Group className="mb-5">
                    

                        <Form.Label htmlFor="inputNameCustomer">Unidade de medida</Form.Label>
                        {select}

                    </Form.Group>

                    <Form.Group className="mb-5">
                        <Form.Label htmlFor="inputNameCustomer">Custo</Form.Label>
                        {material
                            ? <NumericFormat 
                                className="form-control" 
                                placeholder="custo" 
                                value={cost}
                                displayType="input"
                                valueIsNumericString={true}
                                decimalSeparator="," 
                                type="text"
                                thousandSeparator="."
                                onValueChange={(values) => {
                                    const { value } = values;
                                    setCost(Number(value))
                                }}
                                />
                            : <NumericFormat 
                                className="form-control" 
                                placeholder="custo" 
                                displayType="input"
                                valueIsNumericString={true}
                                decimalSeparator="," 
                                type="text"
                                thousandSeparator="."
                                onValueChange={(values) => {
                                    const { value } = values;
                                    setCost(Number(value))
                                }}
                               />
                        }
                    </Form.Group>

                    <Form.Group className="mb-5">
                        <Form.Label htmlFor="inputNameCustomer">Alíquota</Form.Label>
                        {material
                            ? <NumericFormat 
                                className="form-control" 
                                placeholder="Alíquota" 
                                value={aliquota}                                
                                displayType="input"
                                valueIsNumericString={true}
                                decimalSeparator="," 
                                type="text"
                                thousandSeparator="."
                                onValueChange={(values) => {
                                    const { value } = values;
                                    setAliquota(Number(value))
                                }}
                                />
                            : <NumericFormat 
                                className="form-control" 
                                placeholder="Alíquota"                   
                                displayType="input"
                                valueIsNumericString={true}
                                decimalSeparator="," 
                                type="text"
                                thousandSeparator="."
                                onValueChange={(values) => {
                                    const { value } = values;
                                    setAliquota(Number(value))
                                }}
                              />
                        }
                    </Form.Group>

                    <Button type="button" className="button-send" onClick={(e) => handleClick(e)}>
                        Enviar
                    </Button>
                    <Button type="button" className="button-cancel" onClick={(e) => handleCancel(e)}>
                        Cancelar
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default RegisterMaterial;