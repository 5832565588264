import React, { useEffect, useState, Fragment } from "react";

/** imports componentes */
import {
  Button,
  Modal,
  Form,
  Table,
  Toast,
  Row,
  Col,
  ToastContainer,
  Alert,
} from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"; //the use searchParams is used to get params of uri
import ptBR from "date-fns/locale/pt-BR";
import { registerLocale, setDefaultLocale } from "react-datepicker";
/* import styles   */
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import {
  Trash,
  PencilSquare,
  SaveFill,
  CheckCircle,
  Pencil,
  ArrowCounterclockwise,
} from "react-bootstrap-icons";

/* import interfaces   */
import IFee from "../../interfaces/IFee";
import feeService from "../../services/FeeService";
import IItemBudget from "../../interfaces/IItemBudget";
import IStageProcess from "../../interfaces/IStageProcess";
import IRawMaterial from "../../interfaces/IRawMaterial";

/* import Services  */
import budgetService from "../../services/BudgetService";
import stageProcessService from "../../services/StageProcessService";
import rawMaterialService from "../../services/RawMaterialService";
import ICustomer from "../../interfaces/ICustomer";
import IBudget from "../../interfaces/IBudget";
import IMaterial from "../../interfaces/IMaterial";
import IPreProcess from "../../interfaces/IPreProcess";
import materialService from "../../services/MaterialService";
import preProcessService from "../../services/PreProcessService";
import { rejects } from "assert";
import IItemComplete from "../../interfaces/IItemComplete";
import { setgroups } from "process";
import { ProfitMargin } from "../../enums/ProfitMargin";
import { NumericFormat } from "react-number-format";
import IProduct from "../../interfaces/IProduct";
import UtilDate from "../../utils/util.date";
import productService from "../../services/ProductService";
import commentService from "../../services/CommentService";
import RandomString from "../../utils/random.string";
import { StatusItemBudget } from "../../enums/StatusItemBudget";
import Barcode from "../../utils/barcode";
import IComment from "../../interfaces/IComment";
import { format } from "date-fns";
import SearchableSelect from "../../components/seachableSelect/searchableSelect";
import { StatusBudget } from "../../enums/StatusBudget";

registerLocale("pt-BR", ptBR);

interface ITax {
  name: string;
  value: number;
}

const Pricing = () => {
  const [fees, setFees] = useState<any>([]);

  const location = useLocation();
  const [item, setItem] = useState<IItemBudget>(location.state?.item);

  const [itemBudget, setItemBudget] = useState<IItemBudget>();
  const [customer, setCustomer] = useState<ICustomer>(location.state?.customer);
  const [budget, setBudget] = useState<IBudget>(location.state?.budget);
  const [stageProcesses, setStageProcesses] = useState<IStageProcess[]>();
  const [rawMaterials, setRawMaterials] = useState<IRawMaterial[]>();
  const [saved, setSaved] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [material, setMaterial] = useState<IMaterial[]>();
  const [preProcess, setPreProcess] = useState<IPreProcess[]>();
  const [products, setProducts] = useState<IProduct[]>(
    location.state?.products
  );
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>(
    location.state?.products
  );
  const [product, setProduct] = useState<IProduct>();
  const [comments, setComments] = useState<IComment[]>([]);
  const [deleteStages, setDeleteStages] = useState<number[]>([]);
  const [deleteMaterials, setDeleteMaterials] = useState<number[]>([]);
  const [fullValue, setFullValue] = useState<number>(0);
  const [taxLessValue, setTaxLessValue] = useState<number>(0);
  const [totalManufacture, setTotalManufacture] = useState<number>(0);
  const [totalMaterial, setTotalMaterial] = useState<number>(0);
  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [save, setSave] = useState<boolean>(false);
  const [productSearch, setProductSearch] = useState<string>("");

  const [description, setDescription] = useState<string>();
  const [observacao, setObservacao] = useState<string>();
  const [name, setName] = useState<string>();
  const [altCode, setAltCode] = useState<string>();
  const [barCode, setBarCode] = useState<string>();
  const [level, setLevel] = useState<string>();
  const [group, setGroup] = useState<string>();

  const [totalSetup, setTotalSetup] = useState<number>(0);
  const [totalExecution, setTotalExecution] = useState<number>(0);

  const [isLoadingItemBudget, setLoadingItemBudget] = useState<boolean>(true);
  const [isLoadingFormula, setIsLoadingFormula] = useState<boolean>(true);
  const [showErrorTest, setShowErrorTest] = useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [isCommentEnabled, setIsCommentEnabled] = useState<boolean>(false);
  const [materialOptions, setMaterialOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const truncate = (num: number, decimalPlaces: number) => {
    if (num.toString().length > num.toString().indexOf(".") + decimalPlaces) {
      return Number(
        num.toString().substring(0, num.toString().indexOf(".") + decimalPlaces)
      );
    } else {
      return num;
    }
  };

  const validateFields = () => {
    console.log(itemBudget?.part_quantity, itemBudget?.fabrication);
    if (
      itemBudget &&
      (itemBudget.part_quantity === null ||
        itemBudget.part_quantity === undefined ||
        Number(itemBudget.part_quantity).toFixed(2) === "0.00")
    ) {
      setErrorMessage("Campo QUANTIDADE DE SETUP não deve ser vazio");
      return false;
    } else if (
      itemBudget &&
      (itemBudget.fabrication === null ||
        itemBudget.fabrication === undefined ||
        Number(itemBudget.fabrication).toFixed(2) === "0.00")
    ) {
      setErrorMessage("Campo Quantidade a fabricar não deve ser vazio");
      return false;
    } else {
      return true;
    }
  };

  const round = (num: number, decimalPlaces: number) => {
    num =
      Math.round((num + Number.EPSILON) * Math.pow(10, decimalPlaces)) /
      Math.pow(10, decimalPlaces);
    return num;
  };

  /** loads */

  const loadComments = (id_item: number) => {
    commentService.getAllByItem(id_item).then((result) => {
      setComments(result.data);
    });
  };

  useEffect(() => {
    console.log(itemBudget);
    if (itemBudget) {
      loadComments(itemBudget.id);
    }
  }, [itemBudget]);

  const filterProducts = (products: IProduct[]) => {
    if (productSearch != "") {
      products = products.filter((product) => {
        return (
          product.name?.toLowerCase().indexOf(productSearch.toLowerCase()) >=
            0 ||
          product.description
            ?.toLowerCase()
            .indexOf(productSearch.toLowerCase()) >= 0
        );
      });
    }

    return products;
  };

  useEffect(() => {
    const filtered = filterProducts(products);
    setFilteredProducts(filtered);
  }, [productSearch]);

  const loadMaterial = () => {
    setLoading(true);
    materialService
      .getAll()
      .then((response) => {
        let materials: IMaterial[] = response.data;
        const options = materials.map((material) => {
          return { label: material.description, value: material.id };
        });
        setMaterialOptions(options);
        setMaterial(materials);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const loadPreProcess = () => {
    setLoading(true);
    preProcessService
      .getAll()
      .then((response) => {
        let preprocess: IPreProcess[] = response.data;
        setPreProcess(preprocess);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMaterial();
    loadPreProcess();
  }, []);

  useEffect(() => {
    let totalSetup = stageProcesses?.reduce(getTotal, 0);
    function getTotal(total: any, item: IStageProcess) {
      return total + item.cost_setup;
    }
    setTotalSetup(totalSetup);

    let totalExecution = stageProcesses?.reduce(
      (total: any, item: IStageProcess) => {
        return total + item.cost_execution;
      },
      0
    );

    setTotalExecution(totalExecution);
  }, [stageProcesses]);

  useEffect(() => {
    let rawMaterialSubtotal = rawMaterials?.reduce(
      (total: any, item: IRawMaterial) => {
        return total + item.total;
      },
      0
    );
    setTotalMaterial(rawMaterialSubtotal);
  }, [rawMaterials]);

  useEffect(() => {
    const randomBarcode = Barcode.generateRandom();
    console.log(randomBarcode);
    setBarCode(randomBarcode);
  }, [altCode]);

  const isCorteLaser = (stage: string) => {
    if (stage?.toUpperCase()?.indexOf("CORTE LASER") >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const loadItemBudget = () => {
    setLoadingItemBudget(true);
    budgetService
      .getItemBudget(item.id)
      .then((response) => {
        let item = response.data;
        setProduct(response.data.product);

        let stages = [...item.stageProcesses];
        stages.forEach((stage) => {
          stage.editing = false;
          stage.status = "saved";
          stage.setup =
            (stage.preProcess.setup / item.part_quantity) * stage.operators;
          stage.cost_setup = (stage.cost_hour / 60) * stage.setup;

          let cost_per_minute = stage.cost_hour / 60;
          if (isCorteLaser(stage.preProcess?.description)) {
            stage.cost_execution =
              cost_per_minute * stage.execution * stage.operators;
          } else {
            stage.cost_execution =
              cost_per_minute * stage.execution +
              stage.cost_setup * stage.operators;
          }
        });

        let materials = [...item.rawMaterials];
        materials.forEach((material) => {
          material.editing = false;
          material.status = "saved";
          material.total =
            material.quantity * material.cost -
            material.quantity * material.cost * (material.fee / 100);
        });

        setStageProcesses(stages);
        setRawMaterials(materials);
        setItemBudget(item);

        setLoadingItemBudget(false);
      })
      .catch((error) => {
        setLoadingItemBudget(false);
      });

    setLoading(true);
    feeService
      .getAll(customer.state)
      .then((response) => {
        let tax = response.data;
        setFees(tax);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const calculateAll = () => {
    setIsLoadingFormula(true);
    let stageProcess = stageProcesses;
    if (stageProcess) {
      for (let stage of stageProcess) {
        if (itemBudget) {
          let process = preProcess?.find((p) => p.id == stage.preProcess?.id);
          let setup = process?.setup ? process?.setup : 10;
          stage.setup = (setup / itemBudget.part_quantity) * stage.operators;
        }
        stage.cost_setup = (stage.cost_hour / 60) * stage.setup;

        let cost_per_minute = stage.cost_hour / 60;
        if (isCorteLaser(stage.preProcess?.description)) {
          stage.cost_execution =
            cost_per_minute * stage.execution * stage.operators;
        } else {
          stage.cost_execution =
            cost_per_minute * stage.execution +
            stage.cost_setup * stage.operators;
        }
      }
      setStageProcesses(stageProcess);
    }
    let materialsCalc = rawMaterials;
    if (materialsCalc) {
      for (let materialC of materialsCalc) {
        let mPercentage: number = 0;
        if (materialC.percentage >= 0) {
          mPercentage = materialC.percentage / 100 + 1;
        } else {
          mPercentage = 1 - materialC.percentage * -0.01;
        }
        materialC.total =
          (materialC.quantity * materialC.cost -
            materialC.quantity * materialC.cost * (materialC.fee / 100)) *
          mPercentage;
      }
    }
    let totalSetup: number = 0;
    let totalExecution: number = 0;
    let totalMaterial: number = 0;
    if (stageProcesses) {
      for (let stage of stageProcesses) {
        totalSetup += Number(stage.cost_setup);
        totalExecution += Number(stage.cost_execution);
      }
    }
    setTotalSetup(totalSetup);
    setTotalExecution(totalExecution);

    if (rawMaterials) {
      for (let material of rawMaterials) {
        totalMaterial += Number(material.total);
      }
    }
    let manufacture = totalSetup + totalExecution;
    let index = null;
    if (itemBudget?.interest_fee) {
      index = Math.floor(itemBudget?.interest_fee)?.toString();
    }

    let totalManufactureTruncated: number = 0;
    if (index) {
      let icms = 0;
      let charges: number = 0;
      if (itemBudget?.comission) {
        charges += Number(itemBudget.comission);
      }
      if (itemBudget?.interest_fee) {
        charges += Number(itemBudget?.interest_fee);
      }
      for (let fee of fees) {
        charges += Number(fee.value);
        if (fee.name == "ICMS") {
          icms = fee.value;
        }
      }

      let fee = ProfitMargin[index] + charges / 100;
      let totalMaterialTruncated = totalMaterial;
      let value = 0;
      let percentage: number = 0;
      if (itemBudget) {
        percentage = 1 + itemBudget.adjustment / 100;
      }
      if (!fee) {
        value = manufacture + totalMaterialTruncated;
        if (percentage !== 0) {
          value = value * percentage;
        }
        setFullValue(value);
      } else {
        value =
          manufacture +
          totalMaterialTruncated +
          (manufacture + totalMaterialTruncated) * fee;

        if (percentage !== 0) {
          value = value * percentage;
        }
        setFullValue(value);
      }

      let taxless = value - value * (icms / 100);
      setTaxLessValue(taxless);
    }

    setTotalManufacture(manufacture);
    setTotalMaterial(totalMaterial);
    setIsLoadingFormula(false);
  };
  useEffect(() => {
    loadItemBudget();
  }, []);

  useEffect(() => {
    calculateAll();
  }, [stageProcesses, rawMaterials, itemBudget]);

  const handleDeleteStageClick = (id: number) => {
    const item = itemBudget;
    if (item) {
      let stages = [...item.stageProcesses];
      const st = stages.find((st) => st.id == id);
      if (st?.status === "saved") {
        let ids = [...deleteStages];
        ids?.push(id);
        setDeleteStages(ids);
      }
      stages = stages.filter((stage) => stage.id != id);
      item.stageProcesses = stages;
      const materials = rawMaterials;
      setItemBudget(item);
      setStageProcesses(stages);
    }
  };

  const addStageProcess = () => {
    const item = itemBudget;
    if (item) {
      const stages = [...item.stageProcesses];
      let maxId = 0;
      if (stages.length > 0) {
        maxId = Math.max(...stages.map((stage) => stage.id));
      }
      stages.push({
        id: maxId + 1,
        description: "Etapa nova",
        operators: 1,
        cost_hour: 0,
        execution: 0,
        cost_execution: 0,
        cost_setup: 0,
        percentage: 0,
        setup: 0.001,
        editing: true,
        status: "new",
      } as IStageProcess);
      item.stageProcesses = stages;
      setItemBudget(item);
      setStageProcesses(stages);
    }
  };

  const addRawMaterial = () => {
    const item = itemBudget;
    if (item) {
      let maxId = 0;
      const materials = [...item.rawMaterials];
      if (materials.length > 0) {
        maxId = Math.max(...materials.map((material) => material.id));
      }
      materials.push({
        id: maxId + 1,
        description: "",
        standard: "",
        measurement_unit: "",
        quantity: 0,
        cost: 2.9,
        fee: 2.9,
        percentage: 0,
        total: 0,
        editing: true,
        status: "new",
      } as IRawMaterial);
      item.rawMaterials = materials;
      setItemBudget(item);
      setRawMaterials(materials);
    }
  };

  const updateRawMaterial = (
    rawMaterialEditing: IRawMaterial,
    attribute: string,
    value: any
  ) => {
    if (rawMaterials && itemBudget) {
      let materials = [...rawMaterials];
      materials = materials.map((material) => {
        if (material.id == rawMaterialEditing.id) {
          material[attribute] = value;

          if (material.status === "saved") {
            material.status = "updated";
          }
        }

        return material;
      });

      const stages = stageProcesses;
      setRawMaterials(materials);
    }
  };
  const updateDescriptionRawMaterial = (
    rawMaterialEditing: IRawMaterial,
    value?: any
  ) => {
    if (rawMaterials && itemBudget) {
      let rawMaterial = [...rawMaterials];
      let ma = material?.find((m) => m.id == value);
      rawMaterial = rawMaterial.map((rMaterial) => {
        if (ma) {
          if (rMaterial.id == rawMaterialEditing.id) {
            rMaterial.description = ma.description;
            rMaterial.standard = ma.standard;
            rMaterial.measurement_unit = ma.units_of_measurement;
            rMaterial.cost = ma.cost;
            rMaterial.fee = ma.aliquota;
            rMaterial.id_material = ma.id;
            if (rMaterial.status === "saved") {
              rMaterial.status = "updated";
            }
          }
        }
        return rMaterial;
      });
      setRawMaterials(rawMaterial);
    }
  };

  const deleteRawMaterial = (id: number) => {
    const item = itemBudget;
    if (item) {
      let materials = [...item.rawMaterials];
      const ma = materials.find((m) => m.id == id);
      if (ma?.status === "saved") {
        let idm = [...deleteMaterials];
        idm?.push(id);
        setDeleteMaterials(idm);
      }
      materials = materials.filter((material) => material.id != id);
      item.rawMaterials = materials;
      const stages = stageProcesses;
      setItemBudget(item);
      setRawMaterials(materials);
    }
  };

  useEffect(() => {});

  const updateStage = (
    stageEditing: IStageProcess,
    attribute: string,
    value: any
  ) => {
    if (stageProcesses && itemBudget) {
      let stages = [...stageProcesses];
      stages = stages.map((stage) => {
        if (stage.id == stageEditing.id) {
          stage[attribute] = value;
          if (stage.status === "saved") {
            stage.status = "updated";
          }
        }

        return stage;
      });
      setStageProcesses(stages);

      const item = itemBudget;
      item.stageProcesses = stages;
    }
  };

  const updateDescriptionStage = (stageEditing: IStageProcess, value?: any) => {
    if (stageProcesses && itemBudget) {
      let stages = [...stageProcesses];
      let pre = preProcess?.find((p) => p.id == value);
      stages = stages.map((stage) => {
        if (pre) {
          if (stage.id == stageEditing.id) {
            stage.description = pre.description;
            stage.preProcess = pre;
            stage.cost_hour = pre.cost_hour;
            stage.id_process = pre.id;
            if (stage.status === "saved") {
              stage.status = "updated";
            }
          }
        }
        return stage;
      });
      setStageProcesses(stages);
    }
  };

  const backToBudget = () => {
    navigate("/pre-budget", {
      state: {
        budget: budget,
        customer: customer,
      },
    });
  };

  const handleSave = () => {
    const shouldExecute = validateFields();
    console.log(shouldExecute);
    if (shouldExecute) {
      let process: any[] = [];
      if (comment != "" && itemBudget) {
        const commentData: {
          text: string;
          id_entity: number;
          type: "item" | "budget";
        } = { text: comment, id_entity: itemBudget.id, type: "item" };
        process.push(commentService.create(commentData));
      }
      stageProcesses?.map((stage: IStageProcess) => {
        const { editing, id, ...data1 } = stage;
        if (data1.status === "new") {
          data1["id_budget"] = itemBudget?.id;
          process.push(
            stageProcessService.create(data1).then((result) => {
              stage.status = "saved";
            })
          );
        } else if (data1.status === "updated") {
          if (itemBudget) {
            data1.id_item = itemBudget.id;
          }
          data1.id_process = stage.preProcess.id;
          process.push(
            stageProcessService.update(data1, id).then((result) => {
              stage.status = "saved";
            })
          );
        }
      });

      let rMaterials: any[] = [];
      rawMaterials?.map((material: IRawMaterial) => {
        const { editing, id, ...data2 } = material;
        if (data2.status === "new") {
          data2["id_budget"] = itemBudget?.id;
          rMaterials.push(
            rawMaterialService.create(data2).then((result) => {
              material.status = "saved";
            })
          );
        } else if (data2.status === "updated") {
          if (itemBudget) {
            data2.id_item = itemBudget.id;
          }
          data2.id_material = material.material.id;
          rMaterials.push(
            rawMaterialService.update(data2, id).then((result) => {
              material.status = "saved";
            })
          );
        }
      });

      let delete1: any[] = [];
      deleteMaterials?.forEach((item) => {
        delete1.push(rawMaterialService.delete(item));
      });

      let delete2: any[] = [];
      deleteStages?.forEach((item) => {
        delete2.push(stageProcessService.delete(item));
      });
      const promises: any[] = [];
      promises.push(...process, ...rMaterials, ...delete1, ...delete2);

      Promise.all(promises)
        .then((res) => {
          let completeData = {
            thickness: itemBudget?.thickness,
            part_quantity: itemBudget?.part_quantity,
            fabrication: itemBudget?.fabrication,
            comission: itemBudget?.comission,
            interest_fee: itemBudget?.interest_fee,
            total_value: totalManufacture + totalMaterial,
            fullValue: fullValue,
            taxLessValue: taxLessValue,
            productId: product?.id,
            adjustment: itemBudget?.adjustment,
          };
          if (itemBudget) {
            budgetService
              .completeItemBudget(itemBudget.id, completeData)
              .then(() => {
                backToBudget();
              })
              .catch((err) => {
                setError(true);
                setSave(false);
              });
          }
        })
        .catch((err) => {
          setError(true);
          setSave(false);
        });
    } else {
      setSave(false);
    }
  };

  const changeProduct = (product: IProduct) => {
    setProduct(product);
    handleClose();
  };

  const handleUndo = () => {
    setProduct(itemBudget?.product);
  };

  const createProduct = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      filteredProducts.some((obj) => obj.codigo_alternativo === altCode) ||
      filteredProducts.some((obj) => obj.description === description)
    ) {
      setShowErrorTest(true);
      return;
    }
    let data = {
      description: description,
      name: description,
      codigo_alternativo: altCode,
      codigo_barras: barCode,
      customer: customer.id,
      observacao: observacao,
    };

    productService
      .createSync(data)
      .then((response) => {
        let product: IProduct = response.data;

        productService
          .getById(product.id)
          .then((response) => {
            setProduct(response.data);
            handleClose();
          })
          .catch((error) => {
            alert(error?.response?.data?.message);
          });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const changeModal = (side: any) => {
    if (side === 1) {
      setShow(false);
      setShowCreate(true);
    } else {
      setShow(true);
      setShowCreate(false);
    }
  };

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    setShowCreate(false);
  };

  useEffect(() => {
    if (showErrorTest) {
      setTimeout(() => setShowErrorTest(false), 10000);
    }
  }, [showErrorTest]);

  useEffect(() => {
    console.log(product, itemBudget);
    if (product && itemBudget) {
      budgetService
        .checkIfExists(
          budget.id,
          product.description,
          product.codigo_alternativo,
          itemBudget.id,
          String(itemBudget.version)
        )
        .then((result) => {
          console.log(result);
          setIsDuplicated(result);
        });
    }
  }, [product]);

  if (itemBudget) {
    return (
      <div className="container-fee">
        <div className="container-fluid">
          <a
            href="javascript: void(0)"
            className="btn btn-secondary mt-2"
            onClick={backToBudget}
          >
            Voltar para o orçamento
          </a>

          <div className="float-left m-2">
            <h1>CUSTO - PDM</h1>
          </div>

          <div className="row py-3 container-pricing-header">
            <h4 className="col-4">CÓDIGO: {itemBudget.id}</h4>
            {product == itemBudget.product && (
              <h4 className="col-4">
                DESCRIÇÃO: {itemBudget.product.description}
                {item.status != StatusItemBudget.SENT && (
                  <Button size="sm" onClick={handleShow}>
                    <Pencil />
                  </Button>
                )}
              </h4>
            )}
            {product != itemBudget.product && product && (
              <h4 className="col-4">
                DESCRIÇÃO: {product.description}
                <Button variant="danger" size="sm" onClick={handleUndo}>
                  <ArrowCounterclockwise />
                </Button>
              </h4>
            )}
            <h4 className="col-4">
              ESPESSURA (mm):{" "}
              <NumericFormat
                name="thickness"
                valueIsNumericString={true}
                decimalSeparator=","
                displayType="input"
                placeholder="Espessura"
                className="form-control"
                value={itemBudget.thickness}
                type="text"
                thousandSeparator="."
                onValueChange={(values) => {
                  const { value } = values;
                  setItemBudget({
                    ...itemBudget,
                    thickness: Number(value),
                  });
                }}
              />
            </h4>
            <h4 className="col-4">
              QUANTIDADE DE SETUP:{" "}
              <NumericFormat
                name="part_quantity"
                placeholder="Qtd de SETUP"
                className="form-control"
                value={itemBudget.part_quantity}
                decimalScale={0}
                type="text"
                valueIsNumericString={true}
                decimalSeparator=","
                thousandSeparator="."
                displayType="input"
                onValueChange={(values) => {
                  const { value } = values;
                  setItemBudget({
                    ...itemBudget,
                    part_quantity: Number(value),
                  });
                }}
              />{" "}
            </h4>
            <h4 className="col-4">
              Quantidade a fabricar:
              <NumericFormat
                name="fabrication"
                placeholder="Quantidade a fabricar"
                className="form-control"
                type="text"
                valueIsNumericString={true}
                decimalSeparator=","
                thousandSeparator="."
                displayType="input"
                value={Math.floor(itemBudget.fabrication)}
                onValueChange={(values) => {
                  const { value } = values;
                  setItemBudget({
                    ...itemBudget,
                    fabrication: Number(value),
                  });
                }}
              />
            </h4>
            <h4 className="col-4">
              COMISSÃO:
              <NumericFormat
                value={itemBudget.comission}
                placeholder="Comissão"
                className="form-control"
                suffix="%"
                type="text"
                valueIsNumericString={true}
                decimalSeparator=","
                thousandSeparator="."
                displayType="input"
                name="comission"
                onValueChange={(values) => {
                  const { value } = values;
                  setItemBudget({
                    ...itemBudget,
                    comission: Number(value),
                  });
                }}
              />{" "}
            </h4>
            <h4 className="col-4">
              MARGEM DE LUCRO:
              <NumericFormat
                type="text"
                suffix="%"
                valueIsNumericString={true}
                name="interest_fee"
                placeholder="Margem de lucro"
                className="form-control"
                decimalSeparator=","
                thousandSeparator="."
                value={itemBudget.interest_fee}
                onValueChange={(values) => {
                  const { value } = values;
                  setItemBudget({
                    ...itemBudget,
                    interest_fee: Number(value),
                  });
                }}
              />
            </h4>
          </div>

          <div className="row py-3 container-tax">
            {!isLoading &&
              fees?.map((tax: ITax) => (
                <label className="col-4" key={tax.name}>
                  <strong>{tax.name}:</strong>{" "}
                  {<label>{`${Number(tax.value)?.toFixed(2)}%`}</label>}
                </label>
              ))}
          </div>

          {!isLoadingFormula && (
            <div className="container-costs">
              <Row>
                <Col>
                  <strong>Custo de mão-de-obra</strong>
                </Col>
                <Col>
                  <NumericFormat
                    prefix="R$"
                    displayType="text"
                    valueIsNumericString={true}
                    value={totalManufacture?.toFixed(2)}
                    decimalSeparator=","
                    type="text"
                    thousandSeparator="."
                  />
                </Col>
                <Col>
                  <strong>Desconto/Acréscimo</strong>
                </Col>
                <Col>
                  <NumericFormat
                    suffix="%"
                    displayType="input"
                    valueIsNumericString={true}
                    value={itemBudget.adjustment}
                    onValueChange={(values) => {
                      const { value } = values;
                      setItemBudget({
                        ...itemBudget,
                        adjustment: Number(value),
                      });
                    }}
                    decimalSeparator=","
                    type="text"
                    thousandSeparator="."
                    className="form-control"
                  />
                </Col>
              </Row>
              <Row className="row-two">
                <Col>
                  <strong>Custo de material</strong>
                </Col>
                <Col>
                  <NumericFormat
                    prefix="R$"
                    displayType="text"
                    valueIsNumericString={true}
                    value={totalMaterial?.toFixed(2)}
                    decimalSeparator=","
                    type="text"
                    thousandSeparator="."
                  />
                </Col>
                <Col>
                  <strong>Preço de Venda S/ Impostos</strong>
                </Col>
                <Col>
                  <NumericFormat
                    prefix="R$"
                    displayType="text"
                    valueIsNumericString={true}
                    value={taxLessValue?.toFixed(2)}
                    decimalSeparator=","
                    type="text"
                    thousandSeparator="."
                  />
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col></Col>
                <Col>
                  <strong>Preço de Venda C/ Impostos</strong>
                </Col>
                <Col>
                  <NumericFormat
                    prefix="R$"
                    displayType="text"
                    valueIsNumericString={true}
                    value={fullValue?.toFixed(2)}
                    decimalSeparator=","
                    type="text"
                    thousandSeparator="."
                  />
                </Col>
              </Row>
            </div>
          )}

          <div>
            <h3>Ficha técnica dos processos</h3>
            {item.status != StatusItemBudget.SENT && (
              <button
                className="btn btn-primary button-top-left"
                onClick={() => addStageProcess()}
              >
                Adicionar processo
              </button>
            )}
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Processo</th>
                  <th> N.º Operadores</th>
                  <th>Setup</th>
                  <th>Execução</th>
                  <th>Custo hora</th>
                  <th>Custo setup</th>
                  <th>Custo execução</th>
                  <th> Ações</th>
                </tr>
              </thead>
              <tbody>
                {!isLoadingItemBudget &&
                  stageProcesses?.map((stage: IStageProcess) => {
                    return (
                      <tr key={stage.id}>
                        <td>{stage.id}</td>
                        <td>
                          {stage.editing == true && (
                            <select
                              className="form-control"
                              defaultValue={stage.id_process}
                              onChange={(e) =>
                                updateDescriptionStage(stage, e.target.value)
                              }
                            >
                              <option></option>
                              {preProcess?.map((preProcess: IPreProcess) => {
                                let selected = false;
                                if (
                                  stage.description == preProcess.description
                                ) {
                                  selected = true;
                                }
                                return (
                                  <option
                                    value={preProcess.id}
                                    selected={selected}
                                  >
                                    {preProcess.description}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                          {!stage.editing && stage.description}
                        </td>
                        <td>
                          {stage.editing == true && (
                            <NumericFormat
                              type="text"
                              valueIsNumericString={true}
                              decimalSeparator=","
                              thousandSeparator="."
                              displayType="input"
                              onValueChange={(values) => {
                                const { value } = values;
                                updateStage(stage, "operators", value);
                              }}
                              className="form-control"
                              name="operators"
                              placeholder="Operadores"
                              value={stage.operators}
                            />
                          )}
                          {!stage.editing && stage.operators}
                        </td>
                        <td>
                          {stage.editing == true && (
                            <NumericFormat
                              type="text"
                              valueIsNumericString={true}
                              decimalSeparator=","
                              thousandSeparator="."
                              displayType="input"
                              onValueChange={(values) => {
                                const { value } = values;
                                updateStage(stage, "setup", value);
                              }}
                              className="form-control"
                              name="setup"
                              placeholder="Setup"
                              value={stage.setup}
                              disabled
                            />
                          )}
                          {!stage.editing && (
                            <NumericFormat
                              displayType="text"
                              valueIsNumericString={true}
                              value={stage.setup?.toFixed(4)}
                              decimalSeparator=","
                              type="text"
                              thousandSeparator="."
                            />
                          )}
                        </td>
                        <td>
                          {stage.editing == true && (
                            <NumericFormat
                              type="text"
                              valueIsNumericString={true}
                              decimalSeparator=","
                              thousandSeparator="."
                              displayType="input"
                              onValueChange={(values) => {
                                const { value } = values;
                                updateStage(stage, "execution", value);
                              }}
                              step={0.1}
                              className="form-control"
                              name="execution"
                              placeholder="Execuçào"
                              value={stage.execution}
                            />
                          )}
                          {!stage.editing && (
                            <NumericFormat
                              displayType="text"
                              valueIsNumericString={true}
                              value={stage.execution}
                              decimalSeparator=","
                              type="text"
                              thousandSeparator="."
                            />
                          )}
                        </td>
                        <td>
                          {stage.editing == true && (
                            <NumericFormat
                              type="text"
                              valueIsNumericString={true}
                              decimalSeparator=","
                              thousandSeparator="."
                              displayType="input"
                              onValueChange={(values) => {
                                const { value } = values;
                                updateStage(stage, "cost_hour", value);
                              }}
                              step={0.1}
                              className="form-control"
                              name="cost_hour"
                              placeholder="Custo minuto"
                              value={stage.cost_hour}
                              disabled
                            />
                          )}
                          {!stage.editing && (
                            <NumericFormat
                              prefix="R$ "
                              displayType="text"
                              valueIsNumericString={true}
                              value={stage.cost_hour}
                              decimalSeparator=","
                              type="text"
                              thousandSeparator="."
                            />
                          )}
                        </td>
                        <td>
                          {stage.editing == true && (
                            <NumericFormat
                              type="text"
                              valueIsNumericString={true}
                              decimalSeparator=","
                              thousandSeparator="."
                              displayType="input"
                              onValueChange={(values) => {
                                const { value } = values;
                                updateStage(stage, "cost_setup", value);
                              }}
                              step={0.1}
                              className="form-control"
                              name="cost_setup"
                              placeholder="Custo Setup"
                              value={
                                typeof stage?.cost_setup == "number"
                                  ? stage?.cost_setup?.toFixed(2)
                                  : 0
                              }
                              disabled
                            />
                          )}
                          {!stage.editing && (
                            <NumericFormat
                              prefix="R$ "
                              displayType="text"
                              valueIsNumericString={true}
                              value={
                                typeof stage?.cost_setup == "number"
                                  ? stage?.cost_setup?.toFixed(2)
                                  : 0
                              }
                              decimalSeparator=","
                              type="text"
                              thousandSeparator="."
                            />
                          )}
                        </td>
                        <td>
                          {stage.editing == true && (
                            <NumericFormat
                              step={0.1}
                              className="form-control"
                              name="cost_execution"
                              placeholder="Custo Execução"
                              value={stage.cost_execution?.toFixed(2)}
                              disabled
                            />
                          )}
                          {!stage.editing && (
                            <NumericFormat
                              prefix="R$ "
                              displayType="text"
                              valueIsNumericString={true}
                              value={stage.cost_execution?.toFixed(2)}
                              decimalSeparator=","
                              type="text"
                              thousandSeparator="."
                            />
                          )}
                        </td>
                        <td>
                          {item.status != StatusItemBudget.SENT && (
                            <>
                              {stage.editing && (
                                <button
                                  className="btn btn-success"
                                  onClick={() =>
                                    updateStage(stage, "editing", false)
                                  }
                                >
                                  {" "}
                                  <CheckCircle />
                                </button>
                              )}

                              {!stage.editing && (
                                <button
                                  className="btn btn-success"
                                  onClick={() =>
                                    updateStage(stage, "editing", true)
                                  }
                                >
                                  {" "}
                                  <PencilSquare />
                                </button>
                              )}
                              <button
                                className="btn btn-danger"
                                onClick={() => handleDeleteStageClick(stage.id)}
                              >
                                <Trash />
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot className="footProcess">
                <tr>
                  <td colSpan={6}> Total</td>

                  <td>
                    <NumericFormat
                      prefix="R$ "
                      displayType="text"
                      valueIsNumericString={true}
                      value={totalSetup?.toFixed(2)}
                      decimalSeparator=","
                      type="text"
                      thousandSeparator="."
                    />
                  </td>
                  <td>
                    <NumericFormat
                      prefix="R$ "
                      displayType="text"
                      valueIsNumericString={true}
                      value={totalExecution?.toFixed(2)}
                      decimalSeparator=","
                      type="text"
                      thousandSeparator="."
                    />
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </Table>
          </div>
          <div>
            <h3>Ficha técnica de matéria prima</h3>
            {item.status != StatusItemBudget.SENT && (
              <button
                className="btn btn-primary button-top-left"
                onClick={() => addRawMaterial()}
              >
                Adicionar matéria-prima
              </button>
            )}
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Descrição</th>
                  <th>UN</th>
                  <th>Qtd.</th>
                  <th>Custo</th>
                  <th>Alíquota</th>
                  <th>Total</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {!isLoadingItemBudget &&
                  rawMaterials?.map((rMaterial: IRawMaterial) => (
                    <tr key={rMaterial.id}>
                      <td>{rMaterial.id}</td>
                      <td className="description-text">
                        {rMaterial.editing == true && (
                          <SearchableSelect
                            options={materialOptions}
                            selected={rMaterial.description}
                            onChange={(e) => {
                              updateDescriptionRawMaterial(rMaterial, e);
                              console.log("foi");
                            }}
                          />
                          /*<select
                            className="form-control"
                            defaultValue={rMaterial.id_material}
                            onChange={(e) =>
                              updateDescriptionRawMaterial(
                                rMaterial,
                                e.target.value
                              )
                            }
                          >
                            <option></option>
                            {material?.map((material: IMaterial) => {
                              let selected = false;
                              if (
                                rMaterial.description == material.description
                              ) {
                                selected = true;
                              }
                              return (
                                <option value={material.id} selected={selected}>
                                  {" "}
                                  {material.description}{" "}
                                </option>
                              );
                            })}
                          </select>*/
                        )}
                        {!rMaterial.editing && rMaterial.description}
                      </td>
                      <td>
                        {rMaterial.editing == true && (
                          <input
                            type="text"
                            disabled
                            onChange={(e) =>
                              updateRawMaterial(
                                rMaterial,
                                "measurement_unit",
                                e.target.value
                              )
                            }
                            className="form-control"
                            name="unit"
                            placeholder="UN"
                            value={rMaterial.measurement_unit}
                          />
                        )}
                        {!rMaterial.editing && rMaterial.measurement_unit}
                      </td>
                      <td className="qtd-text">
                        {rMaterial.editing == true && (
                          <NumericFormat
                            type="text"
                            valueIsNumericString={true}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="input"
                            onValueChange={(values) => {
                              const { value } = values;
                              updateRawMaterial(rMaterial, "quantity", value);
                            }}
                            className="form-control"
                            name="quantity"
                            placeholder="Quantidade"
                            value={rMaterial.quantity}
                          />
                        )}
                        {!rMaterial.editing && (
                          <NumericFormat
                            type="text"
                            valueIsNumericString={true}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="text"
                            value={rMaterial.quantity}
                          />
                        )}
                      </td>
                      <td>
                        {rMaterial.editing == true && (
                          <NumericFormat
                            type="text"
                            valueIsNumericString={true}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="input"
                            onValueChange={(values) => {
                              const { value } = values;
                              updateRawMaterial(rMaterial, "cost", value);
                            }}
                            step={0.1}
                            className="form-control"
                            name="cost"
                            placeholder="Custo"
                            value={rMaterial.cost}
                          />
                        )}
                        {!rMaterial.editing && (
                          <NumericFormat
                            type="text"
                            prefix="R$ "
                            valueIsNumericString={true}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="text"
                            value={rMaterial.cost}
                          />
                        )}
                      </td>
                      <td>
                        {rMaterial.editing == true && (
                          <NumericFormat
                            type="text"
                            valueIsNumericString={true}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="input"
                            suffix="%"
                            onValueChange={(values) => {
                              const { value } = values;
                              updateRawMaterial(rMaterial, "fee", value);
                            }}
                            step={0.1}
                            className="form-control fee-text"
                            name="fee"
                            placeholder="Alíquota"
                            value={rMaterial.fee}
                            disabled
                          />
                        )}
                        {!rMaterial.editing && (
                          <NumericFormat
                            type="text"
                            valueIsNumericString={true}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="text"
                            suffix="%"
                            value={rMaterial.fee}
                          />
                        )}
                      </td>
                      <td>
                        {rMaterial.editing == true && (
                          <NumericFormat
                            prefix="R$ "
                            type="text"
                            valueIsNumericString={true}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="input"
                            onValueChange={(values) => {
                              const { value } = values;
                              updateRawMaterial(rMaterial, "total", value);
                            }}
                            step={0.1}
                            className="form-control"
                            name="tax"
                            placeholder="Total"
                            value={rMaterial.total}
                            disabled
                          />
                        )}
                        {!rMaterial.editing && (
                          <NumericFormat
                            prefix="R$ "
                            type="text"
                            valueIsNumericString={true}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="text"
                            value={rMaterial.total?.toFixed(2)}
                          />
                        )}
                      </td>
                      <td>
                        {item.status != StatusItemBudget.SENT && (
                          <>
                            {rMaterial.editing && (
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  updateRawMaterial(rMaterial, "editing", false)
                                }
                              >
                                {" "}
                                <CheckCircle />
                              </button>
                            )}

                            {!rMaterial.editing && (
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  updateRawMaterial(rMaterial, "editing", true)
                                }
                              >
                                {" "}
                                <PencilSquare />
                              </button>
                            )}
                            <button
                              className="btn btn-danger"
                              onClick={() => deleteRawMaterial(rMaterial.id)}
                            >
                              <Trash />
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={6}>Total</td>

                  <td colSpan={2}>
                    <NumericFormat
                      prefix="R$ "
                      type="text"
                      valueIsNumericString={true}
                      decimalSeparator=","
                      thousandSeparator="."
                      displayType="text"
                      value={totalMaterial?.toFixed(2)}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>

          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Adicionar comentário"
              onChange={(e) => {
                setIsCommentEnabled(!isCommentEnabled);
              }}
            />
          </Form>
          {isCommentEnabled && (
            <Form.Control
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          )}
          <br />
          <div>
            { comments.length > 0 && 
            <div>
              <h4>
                <b>Texto Livre</b>
              </h4>
              <br />
              {comments.map((comment) => (
                <div className="col-md-12 border-bottom">
                  <div className="bg-white comment-section">
                    <div className="d-flex flex-row user p-2">
                      <div className="d-flex flex-row ml-2">
                        <span className="name font-weight-bold">
                          <b>{comment.user.name}</b>
                        </span>
                        &nbsp; &nbsp;
                        <span>
                          {format(
                            new Date(comment.created_at),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="mt-2 p-2">
                      <p className="comment-content">{comment.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
          </div>

          {saved && (
            <Alert
              variant="success"
              onClose={() => setSaved(false)}
              dismissible
            >
              Salvo com sucesso
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Houve um erro
            </Alert>
          )}

          {errorMessage && (
            <Alert
              variant="danger"
              onClose={() => setErrorMessage(undefined)}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}

          {budget.status !== StatusBudget.SENT && (
            <button
              className="btn btn-success m-2"
              disabled={
                save == true || isDuplicated == true || item.status === StatusItemBudget.DENIED
              }
              onClick={(e) => {
                setSave(true);
                handleSave();
              }}
            >
              Salvar
            </button>
          )}
          {budget.status !== StatusBudget.SENT && (
            <button className="btn btn-danger m-2" onClick={backToBudget}>
              {" "}
              Cancelar
            </button>
          )}
          {itemBudget.user && (
            <div className="d-inline-flex justify-content-end">
              <label className="mx-5">
                {"Última modificação: " +
                  itemBudget.last_modified?.toString()?.slice(0, -14)}
              </label>
              <label>{"modificado por: " + itemBudget.user?.username}</label>
            </div>
          )}
        </div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Alterar Produto </Modal.Title>
            <button
              className="btn btn-info"
              style={{ marginLeft: 20 }}
              onClick={() => {
                changeModal(1);
              }}
            >
              Criar Novo
            </button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h5>Selecione os produtos para adicionar ao orçamento</h5>
              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputSearchProduct">
                  Buscar produto
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="buscar produtos"
                  value={productSearch}
                  onChange={(e) => setProductSearch(e.target.value)}
                />
              </Form.Group>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr className="tr">
                    <th style={{ width: 130 }}>Código Alvo</th>
                    <th style={{ width: 130 }}>Código Alt.</th>
                    <th>Descrição</th>
                    <th>Observação</th>
                    <th style={{ width: 50 }}>Selecionar</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts.map((item: IProduct) => (
                    <tr key={item.id}>
                      <td>
                        <span>{item.code}</span>
                      </td>
                      <td>
                        <span>{item.codigo_alternativo}</span>
                      </td>
                      <td>
                        <span>{item.description}</span>
                      </td>
                      <td>
                        <span>{item.observacao}</span>
                      </td>
                      <td>
                        <button
                          className="btn btn-success"
                          onClick={(e) => changeProduct(item)}
                        >
                          <CheckCircle />
                        </button>
                      </td>
                    </tr>
                  ))}

                  {products.length == 0 && !isLoading && (
                    <tr>
                      <td colSpan={6}>
                        <p>Nenhum produto encontrado.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" show={showCreate} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Criar novo produto</Modal.Title>
            <button
              className="btn btn-info"
              style={{ marginLeft: 20 }}
              onClick={() => {
                changeModal(2);
              }}
            >
              Selecionar outro
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label htmlFor="inputAltCode">Código</Form.Label>
                <Form.Control
                  maxLength={30}
                  type="text"
                  placeholder="código alternativo"
                  onChange={(e) => {
                    setAltCode(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label htmlFor="inputDescriptioProduct">
                  Descrição
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="descrição do produto"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label htmlFor="inputDescriptioProduct">
                  Observação
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="observação do produto"
                  onChange={(e) => setObservacao(e.target.value)}
                />
              </Form.Group>
            </Form>
            <br />
            {showErrorTest && <p id="error-message">Produto já existe!</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => createProduct(e)}
            >
              Criar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Pricing;
