import api from "./api"

class UserService{

    async getAll(): Promise<any>{   
        return api.get(`/user/find-all`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getAllWithFirms(): Promise<any>{   
        return api.get(`/user/find-all-with-firms`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getByToken(): Promise<any>{   
        return api.get(`/user/find-by-token`)
            .then((result) => {
                return Promise.resolve(result)
            })
            .catch((error) => {
                return Promise.reject(error)
            }
        )
    }

    async getById(id: number): Promise<any>{   
        return api.get(`/user/find-one` + id)
            .then((result) => {
                return Promise.resolve(result)
            })
            .catch((error) => {
                return Promise.reject(error)
            }
        )
    }

    async create(data: any): Promise<any>{
        return api({
            url: "/user/create",
            method: "POST",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async updateFirms(data: any, id: number): Promise<any>{
        return api({
            url: `/user/update-firms/${id}`,
            method: "PUT",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async update(data: any, id: any): Promise<any>{
        return api({
            url: `/user/update/${id}`,
            method: "PUT",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async delete(id: any): Promise<any>{   
        return api.delete(`/user/delete/${id}`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    
    
}

const userService = new UserService()
export default userService

