import { ListGroup, Table } from "react-bootstrap";
import "./BudgetPDF.scss";
import IBudget from "../../interfaces/IBudget";
import IItemBudget from "../../interfaces/IItemBudget";
import { CheckLg } from "react-bootstrap-icons";
import { format } from "date-fns";
import { NumericFormat } from "react-number-format";

function BudgetPDF({
  budget,
  items,
  version,
}: {
  budget: IBudget;
  items: IItemBudget[];
  version: number;
}) {
  return (
    <div className="pdfBox">
      <div className="print-outline"></div>
      <div className="page-one">
        <Table style={{ border: "solid 0px grey" }} className="outline">
          <thead>
            <div className="headerComplete">
              <div className="headerLogo">
                { budget.firm && 
                  <>
                  { budget.firm?.name?.toUpperCase()?.indexOf("PROCORTE") >= 0 && 
                    <img src="procorte.png" alt="logo" />
                  }
                  { budget.firm?.name?.toUpperCase()?.indexOf("PROJINOX") >= 0 && 
                    <img src="logo.png" alt="logo" />
                  }
                  </>
                }
              </div>
              <div className="headerLogoIso">
                <img src="logo_iso.png" alt="logo ISO" width="91" className="logo-iso" />
              </div>

              <div className="headerData">
                <div className="name topText">
                  <b>{budget.firm?.name}</b>
                </div>
                <div>
                  <div className="headerText">
                    <div className="topText">
                      <b>CNPJ</b>
                    </div>
                    &nbsp; &nbsp;
                    <div className="topText">{budget.firm?.cnpj}</div>
                  </div>
                  <div className="headerText">
                    <div className="topText">
                      <b>ENDEREÇO</b>
                    </div>
                    &nbsp; &nbsp;
                    <div className="topText">
                      Rua Fronia Kirol Filho, 51 - Jardim Maria de Lourdes
                    </div>
                  </div>
                  <div className="headerText">
                    <div className="topText">
                      <b>CIDADE</b>
                    </div>
                    &nbsp; &nbsp;
                    <div className="topText">Guarulhos</div>
                  </div>
                  <div className="headerText">
                    <div className="topText">
                      <b>TELEFONE</b>
                    </div>
                    <div className="topText">(11) 2480-3461</div>
                  </div>
                </div>
                <div>
                  <div className="headerText">
                    <div className="topText">
                      <b>IE</b>
                    </div>
                    <div className="topText">127756997112</div>
                  </div>

                  <div className="headerText">
                    <div className="topText">
                      <b>CEP</b>
                    </div>
                    <div className="topText">07263-020</div>
                  </div>
                  <div className="headerText">
                    <div className="topText">
                      <b>UF</b>
                    </div>
                    <div className="topText">SP</div>
                  </div>
                  <div className="headerText">
                    <div className="topText">
                      <b>E-MAIL</b>
                    </div>
                    <div className="topText">contato@procortelaser.com.br</div>
                  </div>
                </div>
              </div>
            </div>
          </thead>
          <tbody>
            <div className="budget-title">ORÇAMENTO</div>
            <div className="bodyText">
              <div className="budget-header">
                <div className="budget-text">
                  <b>Orçamento</b>
                </div>
                <div className="budget-number">{`${
                  budget.id
                } - versão ${version.toFixed(1)}`}</div>

                <div className="client">
                  <b>Entidade</b>
                </div>
                <div className="client-data"><strong>{budget.customer.customerscode}</strong> {budget.customer.name}</div>

                <div>
                  <b>Cidade</b>
                </div>
                <div>{budget.customer.city}</div>

                <div>
                  <b>UF</b>
                </div>
                <div>{budget.customer.state}</div>

                <div>
                  <b>CEP</b>
                </div>
                <div>{budget.customer.zipCode}</div>

                <div>
                  <b>CNPJ/CPF</b>
                </div>
                <div>{budget.customer.cnpj}</div>

                <div>
                  <b>Insc./RG</b>
                </div>
                <div>{budget.customer.ie}</div>

                <div>
                  <b>Telefone</b>
                </div>
                <div>{budget.customer.phone}</div>

                <div>
                  <b>Vendedor</b>
                </div>
                <div>{budget?.customer?.salesman?.name}</div>

                <div>
                  <b>Data do orçamento</b>
                </div>
                <div>{format(new Date(budget.created_at), "dd/MM/yyyy")}</div>

                <div>
                  <b>E-MAIL</b>
                </div>
                <div style={{ textTransform: "lowercase"}}>{budget?.customer?.salesman?.username}@procortelaser.com.br</div>

                <div>
                  <b>Cond. Pag.</b>
                </div>
                <div>A combinar</div>
              </div>

              <div>
                <Table>
                  <thead className="t_text">
                    <tr className="tr ">
                      <th>Seq.</th>
                      <th>Código</th>
                      <th>Descrição do produto</th>
                      <th>Un.</th>
                      <th>Qtd</th>
                      <th>M.O</th>
                      <th>F.O</th>
                      <th>Unitário</th>
                      <th>Total (R$)</th>
                      <th>Data Entrega</th>
                    </tr>
                  </thead>
                  <tbody className="t_text">
                    {items.map((item: IItemBudget, index: number) => (
                      <tr key={index}>
                        <td>
                          <span>{index + 1}</span>
                        </td>
                        <td>
                          <span>{item.product.codigo_alternativo}</span>
                        </td>
                        <td>
                          <span>
                            {(item.product.observacao != null && item.product.observacao != undefined && item.product.observacao != "") &&
                              <span>{item.product.description}<br />{item.product.observacao}</span>
                            }
                            {(item.product.observacao == null || item.product.observacao == undefined || item.product.observacao == "") &&
                              <span>{item.product.description}</span>
                            }
                          </span>
                        </td>
                        <td>
                          <span>UN</span>
                        </td>
                        <td>
                          <span>{Math.floor(item.fabrication)}</span>
                        </td>
                        <td>
                          {item.stageProcesses.length > 0 &&
                            item.rawMaterials.length === 0 && <CheckLg />}
                        </td>
                        <td>
                          {item.stageProcesses.length > 0 &&
                            item.rawMaterials.length > 0 && <CheckLg />}
                        </td>
                        <td>
                          <span>
                            <NumericFormat
                              displayType="text"
                              valueIsNumericString={true}
                              value={ (item.rawMaterials.length == 0) ? (
                                Number(item.taxless)
                              ).toFixed(2) : (
                                Number(item.fullValue)
                              ).toFixed(2)}
                              decimalSeparator=","
                              type="text"
                              thousandSeparator="."
                            />
                            {/*(
                              Number(item.total_value) /
                              Math.floor(item.fabrication)
                            ).toFixed(2)*/}
                          </span>
                        </td>
                        <td>
                          <span>
                            <NumericFormat
                              displayType="text"
                              valueIsNumericString={true}
                              value={(item.rawMaterials.length == 0) 
                                ? ((item.taxless) ? item.taxless * item.fabrication : 0).toFixed(2) 
                                : ((item.fullValue) ? item.fullValue * item.fabrication : 0).toFixed(2) }
                              decimalSeparator=","
                              type="text"
                              thousandSeparator="."
                            />
                            {/*item.total_value*/}
                          </span>
                        </td>
                        <td className="combine-text">
                          <span className="combine-text">A Combinar</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="text_container">
              <p className="warning_span lateral_text">
                VALOR MÍNIMO PARA PEDIDOS: R$ 700,00
              </p>
              <div>
                <div className="text_total">
                  <p className="lateral_text">Total Geral Do Orçamento</p>
                  &nbsp; &nbsp;
                  <p className="lateral_text">
                    <NumericFormat
                      prefix="R$ "
                      displayType="text"
                      valueIsNumericString={true}
                      value={items
                        .reduce(
                          (acc: number = 0, item) =>
                            acc +
                            (
                              (item.rawMaterials.length == 0) 
                              ? (item.taxless ? (Number(item.taxless) * item.fabrication) : 0) 
                              : (item.fullValue ? (Number(item.fullValue) * item.fabrication) : 0) 
                            ),
                          0
                        )
                        .toFixed(2)}
                      decimalSeparator=","
                      type="text"
                      thousandSeparator="."
                    />
                    {/*`R$ ${items
                      .reduce(
                        (acc: number = 0, item) =>
                          acc +
                          (item.total_value ? Number(item.total_value) : 0),
                        0
                      )
                      .toFixed(2)}`*/}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <p className="lateral_text">Observações:</p>
              <div className="observation_box">
                <p className="observation_text">
                  {budget.items.map((item) => {
                    return item.comments.map((comment) => (
                      <>
                        {comment.text}
                        <br />
                      </>
                    ));
                  })}
                </p>
              </div>
            </div>

            <div className="list_align">
              <ListGroup horizontal>
                <ListGroup.Item>
                  <div className="list_text">
                    <p className="lateral_text">CL - Corte Laser</p>
                    <p className="lateral_text">D - Dobra</p>
                    <p className="lateral_text">A - Acabamento</p>
                    <p className="lateral_text">FE - Furo Escareado</p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="list_text">
                    <p className="lateral_text">CP - Corte Puncionadeira</p>
                    <p className="lateral_text">M - Montagem</p>
                    <p className="lateral_text">C - Calandra</p>
                    <p className="lateral_text">FR - Furo Rosca</p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="list_text">
                    <p className="lateral_text">CS - Corte Serra</p>
                    <p className="lateral_text">S - Solda</p>
                    <p className="lateral_text">FB - Furo Broca</p>
                    <p className="lateral_text">CQ - Controle Qualidade</p>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="list_text">
                    <p className="lateral_text">CG - Corte Guilhotina</p>
                    <p className="lateral_text">U - Usinagem</p>
                    <p className="lateral_text">CP - Custo Projeto</p>
                    <p className="lateral_text">E - Embalagem</p>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </tbody>
          <tbody>
            <div className="warning-text-title">
              LEIA ATENTAMENTE AS INFORMAÇÕES ABAIXO
            </div>
            <br />
            <div className="bodyText">
              <div className="disclaimer-title">
                "CONDIÇÕES GERAIS NA PRESTAÇÃO DE SERVIÇO DE CORTE, DOBRA E
                CALDEIRARIA":
              </div>
              <p className="disclaimer-text">
                O prazo de fabricação das peças será contado a partir do aceite
                dessa proposta, e de todas as informações necessárias para a
                fabricação, incluindo desenho completo, matéria-prima (em caso
                de mão de obra) e aprovação do cadastro.
                <br />A retirada dos materiais é das 7h45 às 11h45 e das 13h30
                às 16h30. Após esse horário, nenhum material poderá ser retirado
                em nossa expedição.
              </p>
              <div className="disclaimer-title">
                DIMENSÕES MÁXIMAS DE CORTE E DOBRA
              </div>
              <div>
                <ul className="disclaimer-text">
                  <li>{"Laser => 1500x3000mm;"}</li>
                  <li>{"Puncionadeira => 1250x3000mm;"}</li>
                  <li>{"Guilhotina => 2000x3000mm;"}</li>
                  <li>{"Comprimento máximo de dobra => 3000mm;"}</li>
                  <li>
                    {
                      "Em caso de matéria-prima fornecida pelo cliente, estas deverão estar em boas condições para o processo a ser executado (sem oxidação, sem deformação e empenamento excessivo, decapada e oleada, e na tolerância de usina);"
                    }
                  </li>
                  <li>
                    As sobras de material (grade) deverão ser retiradas em até 5
                    dias úteis da retirada de peças.
                  </li>
                </ul>
              </div>
              <div className="disclaimer-title">
                TOLERÂNCIA DE CORTE E DOBRA
              </div>
              <div>
                <ul className="disclaimer-text">
                  <li>{"Laser chapa => ± 0.2mm;"}</li>
                  <li>{"Laser tubo => ± 0.3mm;"}</li>
                  <li>{"Puncionadeira => ± 0.2mm;"}</li>
                  <li>{"Guilhotina => conforme norma NBR 8269/89;"}</li>
                  <li>{"Dobra => conforme norma NBR ISO 2768-1 grau C;"}</li>
                  <li>
                    {
                      "As tolerâncias abaixo das mencionadas não serão consideradas. Tolerâncias na espessura do material seguem conforme as normas NBR 11888 e NBR 11889."
                    }
                  </li>
                </ul>
              </div>
              <div className="disclaimer-title">
                CONTEÚDO GERAL PARA ORÇAMENTO
              </div>
              <div>
                <ul className="disclaimer-text">
                  <li>
                    Os arquivos deverão estar em 2D na extensão em DWG ou DXF.
                    Caso seja em 3D, favor transformar para 2D, nesse caso será
                    conferido apenas a escala, sendo que as peças serão
                    produzidas conforme geometria do desenho enviado pelo
                    cliente;
                  </li>
                  <li>
                    Caso os arquivos forem imagens (PDF, JPG, CDR, etc) ou
                    conteúdo físico, o mesmo será carimbado (SOMENTE PARA
                    ORÇAMENTO) onde o cliente receberá o desenho para aprovação;
                  </li>
                  <li>
                    Furo escariado, rosca e chanfros no sentido da espessura não
                    há possibilidade de corte no processo a laser. Furos menores do que a espessura somente marcação;
                  </li>
                  <li>
                    No processo de dobra podem ocorrer algumas marcas na peça
                    decorrente do ferramental. Furos e rasgos próximos à linha
                    de dobra irão repuxar;
                  </li>
                  <li>
                    Em peças com geometria pequena serão colocadas microjuntas
                    de acordo com o processo de corte laser, que não se
                    caracteriza rebarba;
                  </li>
                  <li>
                    É possível ocorrer riscos na parte inferior da chapa
                    decorrente do contato com a mesa de corte;
                  </li>
                  <li>
                    Para garantir possíveis riscos na superfície das chapas do
                    material com acabamento (aço inox, alumínio, latão, etc), é
                    preciso que a chapa tenha uma película protetora de PVC;
                  </li>
                  <li>
                    As peças para o processo de corte a laser poderão sofrer
                    empenamento devido ao processo de laminação das chapas;
                  </li>
                  <li>
                    Operações não citadas no orçamento não serão executadas;
                  </li>
                  <li>
                    Prazo máximo para reclamações (não conformidade), 5 dias a contar da data de recebimento do material.
                  </li>
                </ul>
              </div>
              <div>
                <ul className="disclaimer-text">
                  <li>
                  A empresa reserva-se do direito de revisar e reajustar os preços estipulados na presente 
                  proposta antes do término do seu prazo de validade. 
                  Tal reajuste poderá ser aplicado em situações excepcionais, como aumentos significativos no custo de matéria-prima, 
                  alterações nas taxas de câmbio, ou a incidência de novos impostos. 
                  Esses ajustes serão comunicados ao cliente de maneira formal e transparente, 
                  com a devida justificativa e especificação dos fatores que levaram ao reajuste proposto. 
                  A empresa compromete-se a fornecer evidências dessas mudanças, caso solicitado pelo cliente.
                  </li>                  
                </ul>
              </div>
            </div>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default BudgetPDF;
