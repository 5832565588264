import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Toast, Row, Col, ToastContainer } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { NumericFormat } from "react-number-format";


import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import "../../shared/global.scss"
import IPreProcess from "../../interfaces/IPreProcess";
import preProcessService from "../../services/PreProcessService";



registerLocale('pt-BR', ptBR)


const PreProcessList = () => {

    const [list, setList] = useState<IPreProcess[]>([])


    const [isLoading, setLoading] = useState<boolean>(true)
    const navigate = useNavigate();

    const loadList = () => {
        setLoading(true)
        preProcessService.getAll().then((response) => {

            let tax: IPreProcess[] = response.data
             console.log(response.data)
            setList(tax)
            setLoading(false)

        }).catch((error) => {
            setLoading(false)
        })

    }
    useEffect(() => {
        loadList()
    }, [])

    const handleClick = async (id: number) => {
        await preProcessService.delete(id)
        loadList()
    }

    const updateFee = (process: IPreProcess) => {
        navigate("/pre-register-process", {
            state: {
              process: process
            }
          })
    }


    return (
        <div className="container-fee">
            <div>
                <h1>
                    Processos
                </h1>
            </div>
            <Link to="/pre-register-process" className="button-top-left btn btn-primary m-2">Adicionar</Link>
            <div className="table">
                <Table striped >
                    <thead>
                        <tr>
                            <th>
                                Codigo
                            </th>
                            <th>
                                Descrição
                            </th>
                            <th>
                                Tempo setup(min)
                            </th>
                            <th>
                                Custo por Hora
                            </th>
                            <th>
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading &&
                            <tr>
                                <td colSpan={4}>
                                    <p>Carregando...</p>
                                </td>
                            </tr>
                        }
                        {
                            list.map((process: IPreProcess) => (
                                <tr key={process.id}>
                                    <td><span>{process.id}</span></td>
                                    <td><span>{process.description}</span></td>
                                    <td>
                                        <span>
                                         <NumericFormat 
                                            prefix=""
                                            value={process.setup}
                                            
                                            type="text"
                                            displayType="text"
                                            valueIsNumericString={true}
                                          />
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <NumericFormat 
                                            prefix="R$ "
                                            value={process.cost_hour}
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            type="text"
                                            displayType="text"
                                            valueIsNumericString={true}
                                          />
                                        </span>
                                    </td>
                                    <td> <Button
                                        onClick={() => updateFee(process)}
                                        className="btn btn-primary">Alterar</Button>
                                        <Button className="btn btn-danger" onClick={() => handleClick(process.id)}>Excluir</Button>
                                        </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default PreProcessList;