import api from "./api"

class RegisterService{
    
    async getAll(id: number): Promise<any>{   
        return api.get("/register/by-item/" + id).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
        
}

const registerService = new RegisterService()
export default registerService