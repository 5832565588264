export default class UtilDate{
    static stringToDMY(stringDate: string){
        const date = new Date(stringDate)
        if (date){
            return date.getDate()?.toString().padStart(2, "0") + "/" + (date.getMonth()+1).toString().padStart(2, "0") + "/" + date.getFullYear()
        }else{
            return ""
        }
    }

    static dateToYMD(date: Date){
        if (date){
            return date.getFullYear() + "-" + (date.getMonth()+1).toString().padStart(2, "0") + "-" + date.getDate()?.toString().padStart(2, "0")
        }else{
            return ""
        }
    }

    static stringToDMYHM(stringDate: string){
        if (stringDate){
            const date = new Date(stringDate)
            if (date){
                return date.getDate()?.toString().padStart(2, "0") + "/" + (date.getMonth()+1).toString().padStart(2, "0") + "/" + date.getFullYear() + " às " + date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0")
            }else{
                return ""
            }
        }else{
            return ""
        }
    }

    static dateToDMY(date: Date){
        if (date){
            return date.getDate()?.toString().padStart(2, "0") + "/" + (date.getMonth()+1).toString().padStart(2, "0") + "/" + date.getFullYear()
        }else{
            return ""
        }
    }

    static dateToDMYHM(date: Date){
        console.log(typeof(date))
        if (date){
            return date.getDate()?.toString().padStart(2, "0") + "/" + (date.getMonth()+1).toString().padStart(2, "0") + "/" + date.getFullYear() + " às " + date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0")
        }else{
            return ""
        }
    }
}