import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss"
import { Button, Modal, Form, FormGroup } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import IPreProcess from "../../interfaces/IPreProcess";
import preProcessService from "../../services/PreProcessService";
import CurrencyInput from 'react-currency-input-field';
import { NumericFormat } from "react-number-format";

const RegisterPreProcess = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation()
    const [process, setProcess] = useState<IPreProcess>(location.state?.process)
    const [description, setDescription] = useState<string>()
    const [setup, setSetup] = useState<number>()
    const [costHour, setCostHour] = useState<number>()
    const navigate = useNavigate();

    const handleClick = (e: React.FormEvent) => {
        e.preventDefault();
        let data = {
            description: description,
            setup: setup,
            cost_hour: costHour
          }
        if(process){
            preProcessService.update(data, process.id)
          .then((response) => {
            navigate("/list-pre-process");
          })
        }else{
            preProcessService.create(data)
          .then((response) => {
            navigate("/list-pre-process");
          })
        }
          
        
    }

    const handleCancel = (e: React.FormEvent) => {
      navigate("/list-pre-process")
    }

    useEffect(() => {
       if(process){
        setDescription(process.description);
        setSetup(process.setup);
        console.log(process.setup)
        setCostHour(process.cost_hour);
       }
    }, [])

    return (
        <div className="container-fluid container-main">
            <div>
                <h1>Atualizar/cadastrar Processos</h1>
            </div>
            <div className="div-form">
                <Form >
                    <Form.Group className="mb-5">
                        <Form.Label htmlFor="inputNameCustomer">Descrição</Form.Label>
                        {process
                        ?<Form.Control type="text" placeholder="descrição do processo" value={description} onChange={(e) => setDescription(e.target.value)} />
                        :<Form.Control type="text" placeholder="descrição do processo" onChange={(e) => setDescription(e.target.value)}/>
                        }
                        
                    </Form.Group>
                    <Form.Group className="mb-5">
                        <Form.Label htmlFor="inputNameCustomer">Tempo setup (minutos)</Form.Label>
                        {process
                        ?
                        <input
                        value={setup} 

                            className="form-control" 
                            type="number"
                            onChange={e=>setSetup(Number(e.target.value))}            
                          />
                        :<input
                            className="form-control" 
                            type="number"
                            onChange={e=>setSetup(Number(e.target.value))}            
                          />
                        }
                        
                    </Form.Group>

                    <Form.Group className="mb-5">
                        <Form.Label htmlFor="inputNameCustomer">Custo por hora</Form.Label>
                        {process
                        ?<NumericFormat 
                            className="form-control" 
                            placeholder="Custo por hora" 
                            value={costHour} 
                            prefix="R$ " 
                            displayType="input"
                            valueIsNumericString={true}
                            decimalSeparator="," 
                            type="text"
                            thousandSeparator="."
                            onValueChange={(values) => {
                                const { value } = values;
                                setCostHour(Number(value))
                            }}                    
                         />
                        :<NumericFormat
                             className="form-control" 
                             placeholder="Custo por hora" 

                             prefix="R$ " 
                            displayType="input"
                            valueIsNumericString={true}
                            decimalSeparator="," 
                            type="text"
                            thousandSeparator="."
                            onValueChange={(values) => {
                                const { value } = values;
                                setCostHour(Number(value))
                            }}            
                          />
                        }
                        
                    </Form.Group>
                    <Button type="submit" className="button-send" onClick={(e) => handleClick(e)}>
                        Enviar
                    </Button>
                    <Button type="submit" className="button-cancel" onClick={(e) => handleCancel(e)}>
                        Cancelar
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default RegisterPreProcess;