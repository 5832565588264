import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss"
import { Button, Modal, Form, FormGroup } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import feeService from "../../services/FeeService";
import IFee from "../../interfaces/IFee";
import { NumericFormat } from "react-number-format";

const RegisterFee = () => {

    const location = useLocation()
    const [tax, setTax] = useState<IFee>(location.state?.tax)
    const [description, setDescription] = useState<string>()
    const [value, setValue] = useState<string>()
    const navigate = useNavigate();

    const handleCancel = (e: React.FormEvent) => {
        navigate("/fee-list")
    }

    const handleClick = (e: React.FormEvent) => {
        e.preventDefault();
        let data = {
            description: description,
            value: Number(value?.replace(",","."))
          }
        if(tax){
            feeService.update(data, tax.id)
          .then((response) => {
            navigate("/fee-list");
          })
        }else{
            feeService.create(data)
          .then((response) => {
            navigate("/fee-list");
          })
        }
    }

    useEffect(() => {
       if(tax){
        setDescription(tax.description);
            setValue(tax.value.toString().replace(".",","));
       }
    }, [])

    return (
        <div className="container-fluid container-main">
            <div>
                <h1>Cadastro de Impostos e Taxas</h1>
            </div>
            <div className="div-form">
                <Form >
                    <Form.Group className="mb-5">
                        <Form.Label htmlFor="inputNameCustomer">Descrição</Form.Label>
                        {tax
                        ?<Form.Control type="text" placeholder="descrição da taxa" value={description} onChange={(e) => setDescription(e.target.value)} />
                        :<Form.Control type="text" placeholder="descrição da taxa" onChange={(e) => setDescription(e.target.value)}/>
                        }
                        
                    </Form.Group>
                    <Form.Group className="mb-5">
                        <Form.Label htmlFor="inputNameCustomer">Percentual</Form.Label>
                        {tax
                        ?<NumericFormat 
                            className="form-control" 
                            placeholder="percentual da taxa" 
                            value={value} 
                            decimalSeparator=","
                            thousandSeparator="."
                            onValueChange={(values) => {
                                const { value } = values;
                                setValue(value)
                            }}
                            onChange={(e) => setValue(e.target.value)}/>
                        :<NumericFormat 
                            className="form-control" 
                            placeholder="percentual da taxa" 
                            decimalSeparator=","
                            thousandSeparator="."
                            onValueChange={(values) => {
                                const { value } = values;
                                setValue(value)
                            }}
                            />
                            
                        }
                        
                    </Form.Group>
                    <Button type="submit" className="button-send" onClick={(e) => handleClick(e)}>
                        Enviar
                    </Button>
                    <Button type="submit" className="button-cancel" onClick={(e) => handleCancel(e)}>
                        Cancelar
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default RegisterFee;