import RandomString from "./random.string";

export default class Barcode{

    static generateRandom(): string{
        const randomString = RandomString.generateString(11)
        const weigthVectorForUPC = [3,1,3,1,3,1,3,1,3,1,3];

        const sum = randomString
            .split("")
            .map(Number)
            .map((digit, index) => digit * weigthVectorForUPC[index])
            .reduce((acc, n) => acc + n)

        const verificationDigit = (Math.abs(((Math.trunc(sum/10) + 1)*10)-sum)).toString()
        const barCode = (randomString + verificationDigit)
   
        return barCode
    }
}