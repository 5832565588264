export const ProfitMargin: any = {
'5': 0.474200,
'6': 0.480700,
'7': 0.487100,
'8': 0.493500,
'9': 0.500000,
'11': 0.512800,
'12': 0.519300,
'13': 0.525700,
'14': 0.532200,
'15': 0.538600,
'16': 0.545100,
'17': 0.551500,
'18': 0.557900,
'19': 0.564400,
'20': 0.570800,
'21': 0.577200,
'22': 0.897075,
'23': 0.590200,
'24': 0.596600,
'25': 0.603000,
'26': 0.609400
}