import { useState, useEffect } from "react";
import { Button, Form, Modal, ModalBody } from "react-bootstrap";
import IComment from "../../interfaces/IComment";
import { format } from "date-fns";
import commentService from "../../services/CommentService";

const CommentModal = ({
  budgetId,
  show,
  hide,
}: {
  budgetId: number;
  show: boolean;
  hide: () => void;
}) => {
  const [comments, setComments] = useState<IComment[]>([]);
  const [comment, setComment] = useState<string>("");
  const [isCommentEnabled, setIsCommentEnabled] = useState<boolean>(false);

  const loadComments = () => {
    commentService.getAllByBudget(budgetId).then((result) => {
      setComments(result.data);
    });
  };

  useEffect(() => {
    loadComments();
  }, [show]);

  const saveComment = () => {
    if (comment !== "") {
      const commentData: {
        text: string;
        id_entity: number;
        type: "item" | "budget";
      } = { text: comment, id_entity: budgetId, type: "budget" };
      commentService.create(commentData).then(() => {
        setComment("");
        setIsCommentEnabled(false);
        hide();
      });
    }
  };

  return (
    <Modal size="lg" show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>Observações Gerais</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Adicionar comentário"
            onChange={(e) => {
              setIsCommentEnabled(!isCommentEnabled);
            }}
          />

          {isCommentEnabled && (
            <Form.Control
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          )}
        </Form>
        {comments.map((comment) => (
          <div className="col-md-12 border-bottom">
            <div className="bg-white comment-section">
              <div className="d-flex flex-row user p-2">
                <div className="d-flex flex-row ml-2">
                  <span className="name font-weight-bold">
                    <b>{comment.user.name}</b>
                  </span>
                  &nbsp; &nbsp;
                  <span>
                    {format(new Date(comment.created_at), "dd/MM/yyyy HH:mm")}
                  </span>
                </div>
              </div>
              <div className="mt-2 p-2">
                <p className="comment-content">{comment.text}</p>
              </div>
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn btn-success" onClick={saveComment}>
          Salvar
        </Button>
        <Button type="submit" className="btn btn-danger" onClick={hide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommentModal;
