import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Table,
  Toast,
  Row,
  Col,
  ToastContainer,
  Spinner,
  Alert,
} from "react-bootstrap";
import api from "../../services/api";
import {
  BsFillExclamationCircleFill,
  BsFillCaretUpFill,
  BsFillCaretDownFill,
} from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import moment from "moment";
import productService from "../../services/ProductService";
import UtilDate from "../../utils/util.date";
import IImportData from "../../interfaces/IImportData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import IStore from "../../interfaces/IStore";
import ISortType from "../../interfaces/ISortType";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ICustomer from "../../interfaces/ICustomer";
import IBudget from "../../interfaces/IBudget";
import IItemBudget from "../../interfaces/IItemBudget";
import { StatusItemBudget } from "../../enums/StatusItemBudget";
import budgetService from "../../services/BudgetService";
import rawMaterialService from "../../services/RawMaterialService";
import stageProcessService from "../../services/StageProcessService";
import { promises } from "stream";
import {
  Eye,
  Files,
  Pencil,
  Trash,
  XSquare,
  XSquareFill,
} from "react-bootstrap-icons";
import LogModal from "../../components/logModal";
import { StatusBudget } from "../../enums/StatusBudget";
import RandomString from "../../utils/random.string";
import feeService from "../../services/FeeService";
import { ProfitMargin } from "../../enums/ProfitMargin";
import Barcode from "../../utils/barcode";
import userService from "../../services/UserService";
import DialogResponse from "./dialogResponse";
import { NumericFormat } from "react-number-format";
import CommentModal from "./commentModal";
registerLocale("pt-BR", ptBR);

interface IProduct {
  [key: string]: string | undefined | number;
  id: number;
  name: string;
  description: string;
  codigo_alternativo: string;
  observacao: string;
  code: string;
}

interface IItemValue {
  [key: string]: string | undefined | number;
  fullValue: number;
  taxless: number;
}

const PreBudgetProductList = (props: any) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isLoadingCreateNewVersion, setIsLoadingCreateNewVersion] = useState<boolean>(false);
  const location = useLocation();
  const [budget, setBudget] = useState<IBudget>(location.state?.budget);
  const [customer, setCustomer] = useState<ICustomer>(location.state?.customer);
  const [filterProduct, setFilterProduct] = useState<string>("");
  const [items, setItems] = useState<IItemBudget[]>([]);
  const [product, setProduct] = useState<IProduct[]>([]);
  const [filteredProduct, setFilteredProduct] = useState<IProduct[]>([]);
  const [productIds, setProductIds] = useState<number[]>([]);
  const [description, setDescription] = useState<string>();
  const [observacao, setObservacao] = useState<string>();
  const [name, setName] = useState<string>();
  const [altCode, setAltCode] = useState<string>();
  const [barCode, setBarCode] = useState<string>();
  const [show, setShow] = useState(false);
  const [filteredItems, setFilteredItems] = useState<IItemBudget[]>([]);
  const [newItemSelect, setNewItemSelect] = useState<boolean>(false);
  const [totalBudget, setTotalBudget] = useState<number>(0);
  const [totalTaxless, setTotalTaxless] = useState<number>(0);
  const [isLoadingSendBudget, setLoadingSendBudget] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showPDF, setShowPDF] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [seller, setSeller] = useState<any>();
  const [budgetSellerId, setBudgetSellerId] = useState<any>();
  const [productSearch, setProductSearch] = useState<string>("");
  const [version, setVersion] = useState<number>(1.0);
  const [availableVersion, setAvailableVersion] = useState<number[]>([]);
  const [message, setMessage] = useState<{
    message: string;
    show: boolean;
    type: "success" | "error";
  }>({ message: "", show: false, type: "success" });
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState<string>();
  const [showComment, setShowComment] = useState<boolean>(false);

  const showCommentModal = () => {
    setShowComment(true);
  };

  const hideCommentModal = () => {
    setShowComment(false);
  };

  const loadItems = () => {
    budgetService
      .getById(budget.id)
      .then((response) => {
        let updatedBudget = response.data;
        let items = updatedBudget.items;
        let itemsBudget: IItemBudget[] = items;
        itemsBudget = filterList(itemsBudget);
        setItems(itemsBudget);
        setBudget(updatedBudget);
        const budget: IBudget = updatedBudget;
        const splitAvailableVersion = budget.availableVersions
          .split(",")
          .reverse();
        console.log(Number(splitAvailableVersion[0]));
        setAvailableVersion(splitAvailableVersion.map((item) => Number(item)));
        if (updatedBudget.status === StatusBudget.SENT) {
          const item = itemsBudget.find(
            (item) => item.status === StatusItemBudget.SENT
          );
          if (item) {
            console.log("test: ", item, splitAvailableVersion);
            setFilteredItems(filterVersion(itemsBudget, Number(item.version)));
            setVersion(Number(item.version));
          }
        } else {
          setFilteredItems(
            filterVersion(itemsBudget, Number(splitAvailableVersion[0]))
          );
          setVersion(Number(splitAvailableVersion[0]));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserByToken = async () => {
    await userService
      .getByToken()
      .then((result) => {
        setUser(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGenerateBudgetPDF = async (budget: IBudget) => {
    await getUserByToken();
    setShowPDF(!showPDF);
  };

  useEffect(() => {
    const randomBarcode = Barcode.generateRandom();
    console.log(randomBarcode);
    setBarCode(randomBarcode);
  }, [altCode]);

  useEffect(() => {
    loadItems();
    setLoading(false);
    setFilteredItems(items);
  }, []);

  useEffect(() => {
    const randomBarcode = Barcode.generateRandom();
    console.log(randomBarcode);
    setBarCode(randomBarcode);
  }, [altCode]);

  const filterVersion = (items: IItemBudget[], version: number) => {
    items = items.filter((item) => {
      return String(item.version) === version.toFixed(1);
    });
    return items;
  };

  useEffect(() => {
    const filtered = filterVersion(items, version);
    setFilteredItems(filtered);
  }, [version]);

  const createNewVersion = () => {
    setIsLoadingCreateNewVersion(true);
    return budgetService
      .createNewVersion(budget.id, version.toFixed(1))
      .then(async () => {
        setLoading(true);
        setIsLoadingCreateNewVersion(false);
        setMessage({
          message: "Nova versão criada com sucesso!",
          show: true,
          type: "success",
        });
        loadItems();
      })
      .catch(() => {
        setIsLoadingCreateNewVersion(false);
        setMessage({
          message: "Houve um erro ao criar uma nova versão, tente novamente.",
          show: true,
          type: "error",
        });
      });
  };

  useEffect(() => {
    setTimeout(
      () => setMessage({ message: "", show: false, type: "success" }),
      3000
    );
  }, [message]);

  const filterList = (items: IItemBudget[]) => {
    if (filterProduct != "") {
      items = items.filter((item) => {
        console.log(item.product);
        return (
          item.product.name
            .toLowerCase()
            .indexOf(filterProduct.toLowerCase()) >= 0 ||
          item.product.description
            ?.toLowerCase()
            ?.indexOf(filterProduct.toLowerCase()) >= 0 ||
          item.product.id
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(filterProduct.toLowerCase()) >= 0 ||
          item.product.codigo_barras
            ?.toLowerCase()
            ?.indexOf(filterProduct.toLowerCase()) >= 0 ||
          item.product.code
            ?.toLowerCase()
            ?.indexOf(filterProduct.toLowerCase()) >= 0 ||
          item.product.codigo_alternativo
            ?.toLowerCase()
            ?.indexOf(filterProduct.toLowerCase()) >= 0
        );
      });
    }

    return items;
  };

  useEffect(() => {
    let itemsBudget = items;
    itemsBudget = filterList(itemsBudget);
    setFilteredItems(itemsBudget);
  }, [filterProduct]);

  const filterProducts = (products: IProduct[]) => {
    if (productSearch != "") {
      products = products.filter((product) => {
        return (
          product.name?.toLowerCase().indexOf(productSearch.toLowerCase()) >=
            0 ||
          product.description
            ?.toLowerCase()
            .indexOf(productSearch.toLowerCase()) >= 0
        );
      });
    }

    return products;
  };

  const loadProduct = () => {
    productService.getAll(customer.id).then((response) => {
      let products: IProduct[] = response.data;
      let filter = filteredItems.map((item) => {
        if (item.product.id) {
          return item.product.id;
        }
      });

      let filtered = products.filter((item) => {
        let filterbool = !filter.find((element) => element === item.id);
        if (filterbool) {
          return item;
        }
      });
      setProduct(filtered);
      setFilteredProduct(filtered);
    });
  };

  useEffect(() => {
    loadProduct();
    calculateTotalBudget();
  }, [filteredItems]);

  useEffect(() => {
    const filtered = filterProducts(product);
    setFilteredProduct(filtered);
  }, [productSearch]);

  const calculateTotalBudget = () => {
    let total: number = 0.0;
    let totalTaxless: number = 0.0;
    for (let item of filteredItems) {
      if (item.fullValue && item.status !== StatusItemBudget.DENIED) {
        total += Number(item.fullValue) * item.fabrication;
        totalTaxless += Number(item.taxless) * item.fabrication;
      }
    }
    setTotalBudget(total);
    setTotalTaxless(totalTaxless);
  };

  const showBudgets = (customer: ICustomer) => {
    navigate("/budget-list", {
      state: {
        customer,
      },
    });
  };

  const editItem = (item: IItemBudget, customer: ICustomer) => {
    navigate("/pricing", {
      state: {
        budget: budget,
        item: item,
        customer: customer,
        products: product,
      },
    });
  };

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();

    let data = {
      description: description,
      name: description,
      codigo_alternativo: altCode,
      codigo_barras: barCode,
      customer: customer.id,
      budgetId: Number(budget.id),
      observacao: observacao,
      version: version,
    };
    budgetService
      .createItemBudget(data)
      .then((response) => {
        loadProduct();
        loadItems();
        handleClose();
      })
      .catch((error) => {
        let message = "Não foi possível cadastrar o produto";
        if (error?.response?.data?.message) {
          message = error?.response?.data?.message;
        }
        setError(true);
        setErrorMessage(message);
      });
  };

  const handleCheckboxProducts = (input: any) => {
    let list = productIds;
    if (input.checked) {
      //se o checkbox foi marcado, adiciona o ID do produto na lista de selecionados
      list = [...list, Number(input.value)];
    } else {
      //se o checkbox foi desmarcado, remove o ID do produto da lista de selecionados
      list = list.filter((item) => item != input.value);
    }
    setProductIds(list);
  };

  const verifyEmpty = () => {
    return filteredItems.some(
      (item) =>
        item.part_quantity === 0 ||
        !item.part_quantity ||
        item.fabrication === 0 ||
        !item.fabrication
    );
  };

  const handleAdd = (e: React.FormEvent) => {
    budgetService
      .addProductsToBudget(budget.id, productIds, version)
      .then((response: any) => {
        loadItems();
        setProductIds([]);
        handleClose();
      })
      .catch((error: any) => {
        alert("Houve um erro ao adicionar os produtos ao orçamento");
      });
  };

  const copy = async (e: React.FormEvent, item: IItemBudget) => {
    let itemData = {
      idItem: item.id,
      description: item.description,
      productId: item.product.id,
      budgetId: Number(budget.id),
      thickness: item.thickness,
      fabrication: item.fabrication,
      comission: item.comission,
      interest_fee: item.interest_fee,
      part_quantity: item.part_quantity,
      fullValue: item.fullValue,
      taxLessValue: item.taxless,
      version: version
    };
    budgetService
      .copyItemBudget(itemData)
      .then((response) => {
        loadItems();
      })
      .catch((error) => {
        alert("Não foi possível duplicar o item do orçamento");
      });
  };

  const handleDelete = (e: React.FormEvent, item: IItemBudget) => {
    if (window.confirm("Deseja realmente excluir o item?")) {
      budgetService
        .removeItem(item.id)
        .then(() => {
          loadItems();
        })
        .catch((error) => {
          alert("Não foi possível excluir o item");
        });
    }
  };

  const handleClose = () => {
    setDescription(undefined);
    setObservacao(undefined);
    setName(undefined);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const sendItens = () => {
    setLoadingSendBudget(true);
    budgetService
      .sendBudget(budget.id, version)
      .then((response) => {
        console.log(response.data);
        setLoadingSendBudget(false);
        loadItems();
      })
      .catch((error) => {
        setLoadingSendBudget(false);
        console.log(error);
        alert(error?.response?.data?.message);
      });
  };

  const denyItem = (id: number) => {
    setLoading(true);
    budgetService.DenyItem(id).then(() => {
      loadItems();
      setLoading(false);
    });
  };

  const putBadgetBackToPreBudget = () => {
    if (window.confirm("Deseja realmente alterar o status do orçamento?")) {
      setLoadingSendBudget(true);

      budgetService
        .turnBudgetToPreBudget(budget.id)
        .then((response) => {
          console.log(response.data);
          setLoadingSendBudget(false);
          loadItems();
        })
        .catch((error) => {
          setLoadingSendBudget(false);
          console.log(error);
          alert(error?.response?.data?.message);
        });
    }
  };

  const removeOnAlvo = () => {
    if (window.confirm("Esse orçamento ja foi enviado! excluir no alvo?")) {
      setLoadingSendBudget(true);
      return budgetService
        .RemoveBudgetFromAlvo(budget.id)
        .then(() => {
          setLoadingSendBudget(false);
          loadItems();
        })
        .catch((error) => {
          setLoadingSendBudget(false);
          console.log("erro - ", error?.response);
          if (error?.response?.data?.message) {
            setAlertMessage(error.response.data.message);
          } else {
            alert("Erro não tratado pela API alvo");
          }
        });
    }
  };

  useEffect(() => {
    if (alertMessage) {
      alert(alertMessage);
    }
  }, [alertMessage]);

  return (
    <>
      <div className={isLoadingSendBudget ? "mouse-change" : ""}>
        <div className="container-fluid main-container">
          <a
            href="javascript: void(0)"
            className="btn btn-secondary mt-2"
            onClick={(e) => showBudgets(budget.customer)}
          >
            Ver orçamentos
          </a>
          <div className="top-bar">
            <div className="title">
              <h3>Orçamento número {budget.id}</h3>
              <h4>{budget.customer.name}</h4>
            </div>
            <div id="container-filters">
              <div className="top-bar-collor">
                <div className="top-text">
                  <h6>Buscar por produto</h6>
                  <input
                    type="text"
                    value={filterProduct}
                    onChange={(e: any) => setFilterProduct(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <Row>
            <Col xs={2} xl={2}>
              <Button className="btn btn-primary m-1" onClick={handleShow}>
                Adicionar produto
              </Button>
            </Col>
            <Col xs={2} xl={2}>
              {budget.status == StatusBudget.SENT && !isLoadingSendBudget && (
                <Button className="btn btn-danger m-1" onClick={removeOnAlvo}>
                  Excluir no alvo
                </Button>
              )}
              
              {budget.status !== StatusBudget.SENT &&
                budget.status !== StatusBudget.DENIED &&
                !isLoadingSendBudget && (
                  <Button
                    className="btn btn-success m-1"
                    onClick={sendItens}
                    disabled={
                      !budget.items.some(
                        (obj) => obj.status === StatusItemBudget.CONFIRMED
                      ) || filteredItems.length == 0 || filteredItems.every(
                        (obj) => obj.status === StatusItemBudget.DENIED
                      )
                    }
                  >
                    Enviar para o Alvo
                  </Button>
                )}
              {budget.status === StatusBudget.DENIED &&
                !isLoadingSendBudget && (
                  <Button className="btn btn-danger m-1" disabled>
                    Orçamento declinado
                  </Button>
                )}
              {isLoadingSendBudget && (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </Col>
            <Col xs={2} xl={2}>
              <Button
                onClick={showCommentModal}
                className="btn btn-secondary m-1"
              >
                Observações Internas
              </Button>
            </Col>
            <Col xs={2} xl={2}>
              <Button
                onClick={(e) => {
                  localStorage.setItem("budgetId", String(budget.id));
                  localStorage.setItem("version", String(version));
                  window.open("/generate-file", "_blank");
                }}
                className="btn btn-primary m-1"
              >
                Gerar PDF
              </Button>
            </Col>
            <Col xs={2} xl={2}>
              { isLoadingCreateNewVersion && 
                <span>Criando versão...</span>
              }
              { !isLoadingCreateNewVersion && 
                <Button
                  className="btn btn-warning m-1"
                  onClick={createNewVersion}
                  disabled={
                    !filteredItems.some(
                      (obj) => obj.status === StatusItemBudget.CONFIRMED
                    ) || budget.status === StatusBudget.SENT
                  }
                >
                  Criar nova versão
                </Button>
              }
            </Col>
            <Col xs={2} xl={2}>
              {availableVersion.length > 0 && !isLoading && (
                <div className="version-container">
                  <h6 className="version-text">Versão:</h6>
                  <select
                    defaultValue={version}
                    value={version}
                    onChange={(e) => setVersion(Number(e.target.value))}
                    className="form-select select-version"
                  >
                    {availableVersion?.map((item: number) => {
                      return <option value={item}>{item.toFixed(1)}</option>;
                    })}
                  </select>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{ fontSize: "1.4em" }}>
                <strong>Total sem impostos</strong>
                <span style={{ marginLeft: 10 }}>
                  <NumericFormat
                    prefix="R$"
                    displayType="text"
                    valueIsNumericString={true}
                    value={totalTaxless?.toFixed(2)}
                    decimalSeparator=","
                    type="text"
                    thousandSeparator="."
                  />
                  {/*R$ {totalTaxless?.toFixed(2)?.toString()?.replace(".", ",")}*/}
                </span>
              </p>
            </Col>
            <Col>
              <p style={{ fontSize: "1.4em" }}>
                <strong>Total com impostos</strong>
                <span style={{ marginLeft: 10 }}>
                  <NumericFormat
                    prefix="R$"
                    displayType="text"
                    valueIsNumericString={true}
                    value={totalBudget?.toFixed(2)}
                    decimalSeparator=","
                    type="text"
                    thousandSeparator="."
                  />
                  {/*R$ {totalBudget?.toFixed(2)?.toString()?.replace(".", ",")}*/}
                </span>
              </p>
            </Col>
          </Row>
          <br />
          {!showPDF && (
            <Table striped bordered hover>
              <thead>
                <tr className="tr">
                  <th>#</th>
                  <th>Código Alvo</th>
                  <th>Código Alt.</th>
                  <th>Descrição</th>
                  <th>Qtd</th>
                  <th>Status</th>
                  <th>Sem imposto</th>
                  <th>Com imposto</th>
                  <th>Ações</th>
                  <th>Logs</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={6}>
                      <p>Carregando...</p>
                    </td>
                  </tr>
                )}
                {filteredItems.map((item: IItemBudget) => (
                  <tr key={item.id}>
                    <td>
                      <span>{item.product.id}</span>
                    </td>
                    <td>
                      <span>{item.product.code}</span>
                    </td>
                    <td>
                      <span>{item.product.codigo_alternativo}</span>
                    </td>
                    <td>
                      <span>{item.product.description}</span>
                    </td>
                    <td>
                      <span>{Math.floor(item.fabrication)}</span>
                    </td>
                    <td>
                      {item.status == StatusItemBudget.PENDING && (
                        <span className="status-pending"> Pendente </span>
                      )}
                      {item.status == StatusItemBudget.CONFIRMED && (
                        <span className="status-confirmed"> Confirmado </span>
                      )}
                      {item.status == StatusItemBudget.SENT && (
                        <span className="status-confirmed"> Enviado </span>
                      )}
                      {item.status == StatusItemBudget.DENIED && (
                        <span className="status-denied"> Declinado </span>
                      )}
                    </td>
                    <td>
                      {item.taxless && (
                        <span>
                          <NumericFormat
                            prefix="R$"
                            displayType="text"
                            valueIsNumericString={true}
                            value={Number(item.taxless).toFixed(2)}
                            decimalSeparator=","
                            type="text"
                            thousandSeparator="."
                          />
                        </span>
                      )}
                      {!item.fullValue && <span>N/D</span>}
                    </td>
                    <td>
                      {item.fullValue && (
                        <span>
                          <NumericFormat
                            prefix="R$"
                            displayType="text"
                            valueIsNumericString={true}
                            value={Number(item.fullValue).toFixed(2)}
                            decimalSeparator=","
                            type="text"
                            thousandSeparator="."
                          />
                        </span>
                      )}
                      {!item.fullValue && <span>N/D</span>}
                    </td>
                    <td className="button-container">
                      {budget.status === StatusBudget.SENT && (
                        <button
                          className="btn btn-primary"
                          onClick={() => editItem(item, customer)}
                          title="Editar"
                        >
                          <Eye />
                        </button>
                      )}
                      {budget.status !== StatusBudget.SENT && (
                        <>
                          {item.status !== StatusItemBudget.SENT && (
                            <button
                              className="btn btn-primary"
                              onClick={() => editItem(item, customer)}
                              title="Editar"
                            >
                              <Pencil />
                            </button>
                          )}

                          {item.status !== StatusItemBudget.SENT && (
                            <button
                              className="btn btn-success"
                              onClick={(e) => copy(e, item)}
                              title="Copiar"
                            >
                              <Files />
                            </button>
                          )}

                          {item.status !== StatusItemBudget.SENT && (
                            <button
                              className="btn btn-danger"
                              onClick={(e) => handleDelete(e, item)}
                              title="Deletar"
                            >
                              <Trash />
                            </button>
                          )}

                          {item.status !== StatusItemBudget.DENIED &&
                            item.status !== StatusItemBudget.SENT && (
                              <button
                                className="btn btn-warning"
                                onClick={(e) => denyItem(item.id)}
                                title="Declinar"
                              >
                                <XSquare color="white" />
                              </button>
                            )}
                        </>
                      )}
                    </td>
                    <td>
                      <LogModal id_budget={item.id} />
                    </td>
                  </tr>
                ))}

                {filteredItems.length == 0 && !isLoading && (
                  <tr>
                    <td colSpan={6}>
                      <p>Nenhum produto neste orçamento.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="modal-large">
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Produto</Modal.Title>
          {!newItemSelect && (
            <button
              className="btn btn-info m-2"
              onClick={() => {
                setNewItemSelect(true);
              }}
            >
              Cadastrar
            </button>
          )}
          {newItemSelect && (
            <button
              className="btn btn-info m-2"
              onClick={() => {
                setNewItemSelect(false);
              }}
            >
              Selecionar
            </button>
          )}
        </Modal.Header>
        <Modal.Body>
          {!newItemSelect && (
            <div>
              <h5>Selecione os produtos para adicionar ao orçamento</h5>
              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputSearchProduct">
                  Buscar produto
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="buscar produtos"
                  value={productSearch}
                  onChange={(e) => setProductSearch(e.target.value)}
                />
              </Form.Group>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr className="tr">
                    <th style={{ width: "30px" }}></th>
                    <th style={{ width: "180px" }}>Código Alvo</th>
                    <th style={{ width: "180px" }}>Código Alt.</th>
                    <th style={{ width: "40%" }}>Descrição</th>
                    <th>Observação</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProduct.map((item: IProduct) => (
                    <tr key={item.id}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          id={`product-${item.id}`}
                          label={""}
                          value={item.id}
                          onChange={(e) => handleCheckboxProducts(e.target)}
                          checked={productIds.includes(item.id)}
                        />
                      </td>
                      <td>
                        <span>{item.code}</span>
                      </td>
                      <td>
                        <span>{item.codigo_alternativo}</span>
                      </td>
                      <td>
                        <span>{item.description}</span>
                      </td>
                      <td>
                        <span>{item.observacao}</span>
                      </td>
                    </tr>
                  ))}

                  {product.length == 0 && !isLoading && (
                    <tr>
                      <td colSpan={6}>
                        <p>Nenhum produto encontrado.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
          {newItemSelect && (
            <Form>
              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputNameCustomer">Código</Form.Label>
                <Form.Control
                  maxLength={30}
                  type="text"
                  placeholder="código do produto"
                  onChange={(e) => setAltCode(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputNameCustomer">Descrição</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="descrição do produto"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputNameCustomer">Observação</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="observação do produto"
                  onChange={(e) => setObservacao(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              {errorMessage}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          {newItemSelect && (
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => handleClick(e)}
            >
              Salvar
            </Button>
          )}

          {!newItemSelect && (
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => handleAdd(e)}
            >
              Adicionar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <DialogResponse {...message} />
      <CommentModal
        budgetId={budget.id}
        show={showComment}
        hide={hideCommentModal}
      />
    </>
  );
};

export default PreBudgetProductList;
