import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Toast, Row, Col, ToastContainer } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { NumericFormat } from "react-number-format";



import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import "../../shared/global.scss"
import IFee from "../../interfaces/IFee";
import feeService from "../../services/FeeService";



registerLocale('pt-BR', ptBR)


const FeeList = () => {

    const [list, setList] = useState<IFee[]>([])


    const [isLoading, setLoading] = useState<boolean>(true)
    const navigate = useNavigate();

    const loadList = () => {
        setLoading(true)
        feeService.getActive().then((response) => {

            let tax: IFee[] = response.data

            setList(tax)
            setLoading(false)

        }).catch((error) => {
            setLoading(false)
        })

    }
    useEffect(() => {
        loadList()
    }, [])

    const handleClick = async (id: number) => {
        await feeService.delete(id)
        loadList()
    }

    const updateFee = (tax: IFee) => {
        navigate("/register-fee", {
            state: {
              tax: tax
            }
          })
    }


    return (
        <div className="container-fee">
            <div>
                <h1>
                    Taxas
                </h1>
            </div>
            <Link to="/register-fee" className="button-top-left btn btn-primary m-2">Adicionar</Link>
            <div className="table">
                <Table striped >
                    <thead>
                        <tr>
                            <th>
                                Código
                            </th>
                            <th>
                                Taxa
                            </th>
                            <th>
                                Percentual
                            </th>
                            <th>
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading &&
                            <tr>
                                <td colSpan={4}>
                                    <p>Carregando...</p>
                                </td>
                            </tr>
                        }
                        {
                            list.map((tax: IFee) => (
                                <tr key={tax.id}>
                                    <td><span>{tax.id}</span></td>
                                    <td><span>{tax.description}</span></td>
                                    <td><span>
                                        <NumericFormat 
                                            value={tax.value} 
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            type="text"
                                            displayType="text"
                                            valueIsNumericString={true}
                                        /></span></td>
                                    <td> <Button
                                        onClick={() => updateFee(tax)}
                                        className="btn btn-primary">Alterar</Button>
                                        <Button className="btn btn-danger" onClick={() => handleClick(tax.id)}>Excluir</Button>
                                        </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default FeeList;