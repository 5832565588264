import api from "./api"

class ProductService{    
    async getAll(customerId: number): Promise<any>{   
        return api.get("/products?customer=" + customerId).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getById(id: number): Promise<any>{   
        return api.get("/products/get-by-id/" + id).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getAllValues(): Promise<any>{   
        return api.get("/products/all-values").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getAllProducts(): Promise<any>{   
        return api.get("/products/all").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async create(data: any): Promise<any>{
        return api({
            url: "/products/create",
            method: "POST",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async createSync(data: any): Promise<any>{
        return api({
            url: "/products/create-sync",
            method: "POST",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async update(data: any, id: any): Promise<any>{
        return api({
            url: `/products/update/${id}`,
            method: "PUT",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async delete(id: any): Promise<any>{   
        return api.delete(`/products/delete/${id}`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
}

const productService = new ProductService()
export default productService