import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Toast, Row, Col, ToastContainer, Alert } from "react-bootstrap";
import api from "../../services/api";
import { BsFillExclamationCircleFill, BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss"
import moment from 'moment';
import productService from "../../services/ProductService";
import UtilDate from "../../utils/util.date";
import IImportData from "../../interfaces/IImportData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import IStore from "../../interfaces/IStore";
import ISortType from "../../interfaces/ISortType";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ICustomer from "../../interfaces/ICustomer";
import budgetService from "../../services/BudgetService";
import RandomString from "../../utils/random.string";
import Barcode from "../../utils/barcode";
registerLocale('pt-BR', ptBR)

interface IProduct {
  [key: string]: string | undefined | number;
  id: number;
  name: string;
  description: string;
  codigo_barras: string;
  codigo_alternativo: string;
  observacao: string;
  code: string;
}

const ProductList = (props: any) => {

  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const location = useLocation()

  const [items, setItems] = useState<IProduct[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const [filterProduct, setFilterProduct] = useState<string>("")
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([])
  const [values, setValues] = useState<any>() //copy of "items" list to show a filtered list
  const [searchParams, setSearchParams] = useSearchParams();
  const [productId, setProductId] = useState<number>()
  const [description, setDescription] = useState<string>()
  const [observacao, setObservacao] = useState<string>()
  const [name, setName] = useState<string>()
  const [altCode, setAltCode] = useState<string>()
  const [barCode, setBarCode] = useState<string>()
  const [level, setLevel] = useState<string>()
  const [group, setGroup] = useState<string>()
  const [customer, setCustomer] = useState<ICustomer>(location.state?.customer)
  const [selectedProductsIds, setSelectedProductsIds] = useState<Number[]>([])
  const [error, setError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const navigate = useNavigate();

  const loadList = (page: number) => {
    setLoading(true)
    productService.getAllValues().then((values) => {
      setValues(values.data)

      productService.getAll(customer.id).then((response) => {
        let products: IProduct[] = response.data
  
        setItems(products)
  
        products = filterList(products)
        setFilteredProducts(products)
          
        setLoading(false)
      }).catch((error) => {
        setLoading(false)
      })
    })
  }

  useEffect(() => {
    const randomBarcode = Barcode.generateRandom()
    console.log(randomBarcode)
    setBarCode( randomBarcode )
  }, [altCode])

  useEffect(() => {
    loadList(1)
  }, [])

  const filterList = (products: IProduct[]) => {

    if (filterProduct != "") {
      products = products.filter((product) => {
        return (product.name?.toLowerCase().indexOf(filterProduct.toLowerCase()) >= 0)
          || (product.description?.toLowerCase().indexOf(filterProduct.toLowerCase()) >= 0)
          || (product.id?.toString()?.toLowerCase().indexOf(filterProduct.toLowerCase()) >= 0)
          || (product.codigo_alternativo?.toLowerCase().indexOf(filterProduct.toLowerCase()) >= 0)
          || (product.codigo_barras?.toString()?.toLowerCase().indexOf(filterProduct.toLowerCase()) >= 0)
          || (product.code?.toLowerCase()?.indexOf(filterProduct.toLowerCase()) >= 0)          
      })
    }

    return products
  }

  useEffect(() => {
    const products = filterList(items)
    setFilteredProducts(products)
  }, [filterProduct])

  useEffect(() => {
    let products = items
    products = filterList(products)
    setFilteredProducts(products)
  }, [filteredProducts])

  const handleCheckboxProducts = (input: any) => {
    let list = selectedProductsIds
    if (input.checked) { //se o checkbox foi marcado, adiciona o ID do produto na lista de selecionados
      list = [...list, Number(input.value)]
    } else { //se o checkbox foi desmarcado, remove o ID do produto da lista de selecionados
      list = list.filter((item) => item != input.value)
    }
    setSelectedProductsIds(list)
  }

  const selectAll = () => {
    setIsCheckAll(!isCheckAll);
    setSelectedProductsIds(items.map(li => li.id));
    if (isCheckAll) {
      setSelectedProductsIds([]);
    }
  }

  const generatePreBudget = () => {
    const firmId = Number(window.sessionStorage.getItem("firmId"))
    budgetService.createPreBudget(selectedProductsIds, customer.id, firmId)
      .then((response) => {
        const data = response.data
        navigate("/pre-budget", {
          state: {
            budget: data,
            customer:customer
          }
        })
      }).catch((error) => {
        alert("Erro: Não foi possível criar o pré-orçamento")
      })
  }

  const handleShowUpdate = (product: IProduct) => {
    setObservacao(product.observacao)
    setDescription(product.description)
    setName(product.name)
    setAltCode(product.codigo_alternativo)
    setBarCode(product.codigo_barras)
    setProductId(product.id)
    handleShow()
  }

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
        if(productId){
          let data = {
            description: description,
            name: name,
            codigo_alternativo: altCode,
            codigo_barras: barCode,
            customer: customer.id,
            observacao: observacao
          }
          productService.update(data, productId)
          .then((response) => {
            loadList(1)
            handleClose()
          }).catch((error) => {
            setError(true)
          }) 
        }else{
          let data = {
            description: description,
            name: name,
            codigo_alternativo: altCode,
            codigo_barras: barCode,
            customer: customer.id,
            observacao: observacao
          }
          productService.create(data)
          .then((response) => {
            loadList(1)
            handleClose()
          }).catch((error)=>{
            if (error.response?.data?.message){
              setErrorMessage(error.response?.data?.message)
            }
            setError(true)
          })
        }
  }

  const handleDelete = async (id: number) => {
    if (window.confirm("Deseja realmente excluir o produto?")){
      productService.delete(id).then((response) => {
        loadList(1)
      }).catch((error) => {
        window.alert("Não foi possível excluir o produto porque está vinculado a orçamentos")
      })
      
    }
  }
  

  const handleClose = () => {
    setDescription(undefined)
    setObservacao(undefined)
    setName(undefined)
    setProductId(undefined)
    setShow(false)
  };
  const handleShow = () => {
    setShow(true)
    setError(false)
  }

  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <Link
            className="btn btn-secondary mt-2"
            to={{
              pathname: "/customer-list",
            }}
          >
            Voltar para lista de Clientes
          </Link>
          <div className="top-bar">
            <div className="title">
              <h3>Produtos</h3>
              <h4>{customer.name}</h4>
            </div>
            <div id="container-filters">
              <div className="top-bar-collor">
                <div className="top-text">
                  <h6>Buscar por produto</h6>
                  <input type="text" value={filterProduct} className="form-control" onChange={(e: any) => setFilterProduct(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>Adicionar produto</Button>
            {selectedProductsIds.length > 0 &&
              <a href="#" onClick={generatePreBudget} className="btn btn-success m-2">Gerar orçamento</a>
            }
          </div>
          <h5>Selecione os produtos para gerar um pré-orçamento</h5>
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th className="first-column">
                  <Form.Check
                    type="checkbox"
                    id={``}
                    label={'Selecionar'}
                    value={''}
                    onClick={selectAll}
                    checked={isCheckAll}
                  />
                </th>
                <th>
                  Código Alvo
                </th>
                <th>
                  Código Alt.
                </th>
                <th>
                  Descrição
                </th>
                <th>
                  Observação
                </th>
                <th>
                  Último Valor
                </th>
                <th>
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading &&
                <tr>
                  <td colSpan={7}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              }
              {
                filteredProducts.map((item: IProduct) => (
                  <tr key={item.id}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={`product-${item.id}`}
                        label={''}
                        value={item.id}
                        onChange={e => handleCheckboxProducts(e.target)}
                        checked={selectedProductsIds.includes(item.id)}
                      />
                    </td>
                    <td><span>{item.code}</span></td>
                    <td><span>{item.codigo_alternativo}</span></td>
                    <td><span>{item.description}</span></td>
                    <td><span>{item.observacao}</span></td>
                    {values[item.id] !== undefined &&
                      <td>{"R$ " + values[item.id]}</td>
                    }
                    {values[item.id] === undefined &&
                      <td>N/A</td>
                    }
                    <td> <Button
                                        onClick={() => handleShowUpdate(item)}
                                        className="btn btn-primary">Alterar</Button>
                                        <Button className="btn btn-danger" onClick={() => handleDelete(item.id)}>Excluir</Button>
                                        
                                        </td>
                  </tr>
                ))}

              {
                (filteredProducts.length == 0 && !isLoading) &&
                <tr>
                  <td colSpan={6}>
                    <p>Nenhum produto encontrado.</p>
                  </td>
                </tr>
              }

            </tbody>
          </Table>

        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="modal-large">
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Produto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form >
          <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Código</Form.Label>
              {productId
                ?<Form.Control maxLength={30} type="text" placeholder="código do produto" value={altCode} onChange={(e) => setAltCode(e.target.value)} />
                :<Form.Control maxLength={30} type="text" placeholder="código do produto" onChange={(e) => setAltCode(e.target.value)}/>
              }
              
          </Form.Group>
          <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Descrição</Form.Label>
              {productId
                ?<Form.Control type="text" placeholder="descrição do produto" value={description} onChange={(e) => setDescription(e.target.value)} />
                :<Form.Control type="text" placeholder="descrição do produto" onChange={(e) => setDescription(e.target.value)}/>
              }
              
          </Form.Group>
          <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Observação</Form.Label>
              {productId
                ?<Form.Control type="text" placeholder="observação" value={observacao} onChange={(e) => setObservacao(e.target.value)} />
                :<Form.Control type="text" placeholder="observação" onChange={(e) => setObservacao(e.target.value)}/>
              }
              
          </Form.Group>
        </Form>
        {error &&
          <Alert variant='danger' onClose={() => setError(false)} dismissible>{errorMessage}</Alert>
        }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" className="btn btn-primary" onClick={(e) => handleClick(e)}>
                        Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductList;
