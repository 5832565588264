import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Toast, Row, Col, ToastContainer } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import IIcms from "../../interfaces/IIcms";
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { NumericFormat } from "react-number-format";



import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import "../../shared/global.scss"
import icmsService from "../../services/IcmsService";



registerLocale('pt-BR', ptBR)


const IcmsList = () => {

    const [list, setList] = useState<IIcms[]>([])


    const [isLoading, setLoading] = useState<boolean>(true)
    const navigate = useNavigate();

    const loadList = () => {
        setLoading(true)
        icmsService.getAll().then((response) => {

            let tax: IIcms[] = response.data

            setList(tax)
            setLoading(false)

        }).catch((error) => {
            setLoading(false)
        })

    }
    useEffect(() => {
        loadList()
    }, [])

    const updateIcms = (tax: IIcms) => {
        navigate("/register-icms", {
            state: {
              tax: tax
            }
          })
    }

    return (
        <div className="container-icms container-fluid">
            <div>
                <h1>
                    ICMS POR ESTADO
                </h1>
            </div>
            <a href="/register-icms" className="button-top-left btn btn-primary m-2">Adicionar</a>
            <div className="table">
                <Table striped >
                    <thead>
                        <tr>
                            <th>
                                Código
                            </th>
                            <th>
                                ICMS
                            </th>
                            <th>
                                ESTADO
                            </th>
                            <th>
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading &&
                            <tr>
                                <td colSpan={4}>
                                    <p>Carregando...</p>
                                </td>
                            </tr>
                        }
                        {
                            list.map((tax: IIcms) => (
                                <tr key={tax.id}>
                                    <td><span>{tax.id}</span></td>
                                    <td><span> 
                                            <NumericFormat 
                                                displayType="text" 
                                                valueIsNumericString={true}
                                                decimalSeparator="," 
                                                type="text"
                                                thousandSeparator="." 
                                                value={tax.quota}/>
                                        </span>
                                    </td>
                                    <td><span>{tax.state_abbreviation}</span></td>
                                    <td> 
                                        <Button
                                        onClick={() => updateIcms(tax)}
                                        className="btn btn-primary">Alterar</Button>

                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default IcmsList;