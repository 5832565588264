import { useEffect, useState } from "react";
import BudgetPDF from "../../components/budgetPDF/BudgetPDF";
import IBudget from "../../interfaces/IBudget";
import budgetService from "../../services/BudgetService";
import IItemBudget from "../../interfaces/IItemBudget";
import { Button } from "react-bootstrap";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { StatusItemBudget } from "../../enums/StatusItemBudget";

const FilePage = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [budgetId, setBudgetId] = useState<number>(
    Number(localStorage.getItem("budgetId"))
  );
  const [budget, setBudget] = useState<IBudget>();
  const [items, setItems] = useState<IItemBudget[]>();
  const [filteredItems, setFilteredItems] = useState<IItemBudget[]>();
  const [version, setVersion] = useState<number>(
    Number(localStorage.getItem("version"))
  );

  const filterVersion = (items: IItemBudget[]) => {
    localStorage.removeItem("version");
    items = items.filter((item) => {
      return String(item.version) === version.toFixed(1);
    });
    return items;
  };

  const filterEmpty = (items: IItemBudget[]) => {
    return items.filter(
      (item) =>
        item.total_value !== undefined &&
        Number(item.total_value) !== 0.0 &&
        item.taxless !== undefined &&
        Number(item.taxless) !== 0.0 &&
        item.status !== StatusItemBudget.DENIED
    );
  };

  const loadItems = () => {
    setIsLoading(true);
    localStorage.removeItem("budgetId");
    budgetService
      .getById(budgetId)
      .then((response) => {
        let updatedBudget: IBudget = response.data;
        console.log(response.data);
        let updatedItems = filterVersion(updatedBudget.items);
        setItems(updatedItems);
        setFilteredItems(filterEmpty(updatedItems));
        setBudget(updatedBudget);
      })
      .finally(() => setIsLoading(false));
  };

  const printDoc = () => {
    let shouldPrint: boolean = false;
    if (
      items?.some(
        (item) =>
          item.total_value === undefined ||
          Number(item.total_value) === 0.0 ||
          item.taxless === undefined ||
          Number(item.taxless) === 0.0
      )
    ) {
      shouldPrint = window.confirm(
        "Esse orçamento possui um ou mais itens não precificados! Deseja continuar?"
      );
    } else {
      shouldPrint = true;
    }
    if (shouldPrint) {
      window.print();
    }
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      <div className="container-fluid main-container" id="container">
        {budget && filteredItems && !isLoading && (
          <div>
            <Button
              onClick={printDoc}
              className="btn btn-primary align_button"
              id="btn-print"
              disabled={
                !items?.some(
                  (item) =>
                    item.status === StatusItemBudget.CONFIRMED ||
                    item.status === StatusItemBudget.SENT
                )
              }
            >
              Imprimir
            </Button>
            <div className="printable">
              <BudgetPDF
                budget={budget}
                items={filteredItems}
                version={version}
              />
            </div>
          </div>
        )}
        {!budget && !isLoading && <div>Houve um erro</div>}
        {isLoading && <div>Aguarde enquanto o pdf é gerado!</div>}
      </div>
    </>
  );
};

export default FilePage;
