import api from "./api";

class CustomerService {
  async getAll(): Promise<any> {
    return api
      .get("/customers")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  async import(code: string, firmId: number): Promise<any> {
    return api
      .post("/customers/import", {
        code,
        firmId,
      })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const customerService = new CustomerService();
export default customerService;
