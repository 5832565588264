import { useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";

const DialogResponse = ({
  message,
  show,
  type,
}: {
  message: string;
  show: boolean;
  type: "success" | "error";
}) => {
  return (
    <Modal size="lg" show={show}>
      <Modal.Header closeButton>
        <Modal.Title className={type === "error" ? "text-danger" : ""}>
          {message}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body></Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default DialogResponse;
