import React, { createContext, useState } from "react";
import IFirm from "../interfaces/IFirm";
 
interface AuthContextData {
   signed: boolean;
   markAsSigned(): void;
   markAsNotSigned(): void;
   firm?: IFirm;
   selectFirm(firm: IFirm): void;
}

interface ProviderProps {
    children: any;
}
 
const AuthContext = createContext<AuthContextData>({} as AuthContextData);
 
export const AuthProvider: React.FC<ProviderProps> = ({children}: React.PropsWithChildren<{}>) => {
  
   const [signed, setSigned] = useState(false)
   const [firm, setFirm] = useState<IFirm | undefined>()
   
   function markAsSigned() {
       setSigned(true)
   }
 
   function markAsNotSigned() {
       setSigned(false)
   }

   function selectFirm(firm: IFirm){
    setFirm(firm)
   }
  
   return (<AuthContext.Provider value={{ signed, markAsSigned, markAsNotSigned, firm, selectFirm }}>
       {children}
   </AuthContext.Provider>
   )
};
 
export default AuthContext;