import { useContext } from "react";
import { Outlet } from "react-router-dom"
import AuthContext from "../../context/auth";
import Sidebar from "../sidebar/Sidebar"
import "./applayout.scss";

const AppLayout = () => {

    const { firm } = useContext(AuthContext);

    return <div>
        <Sidebar />
        <div>
            { firm && 
                <div className="header">
                    <strong>Empresa:</strong> {firm?.name}
                </div>
            }
            <Outlet />
        </div>
    </div>
}

export default AppLayout