import { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Table,
  Toast,
  Row,
  Col,
  ToastContainer,
  FormControl,
} from "react-bootstrap";
import api from "../../services/api";
import {
  BsFillExclamationCircleFill,
  BsFillCaretUpFill,
  BsFillCaretDownFill,
} from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import moment from "moment";
import UtilDate from "../../utils/util.date";
import IImportData from "../../interfaces/IImportData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import IStore from "../../interfaces/IStore";
import ISortType from "../../interfaces/ISortType";
import customerService from "../../services/CustomerService";
import { Link, useNavigate } from "react-router-dom";
import ICustomer from "../../interfaces/ICustomer";
import { PatternFormat } from "react-number-format";
import {
  Arrow90degLeft,
  Arrow90degRight,
  ArrowLeft,
  ArrowRight,
  InfoCircle,
  Paperclip,
  Pencil,
  Plus,
  PlusLg,
  Search,
} from "react-bootstrap-icons";
import budgetService from "../../services/BudgetService";
import AuthContext from "../../context/auth";

registerLocale("pt-BR", ptBR);

const CustomerList = () => {
  const [items, setItems] = useState<ICustomer[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [filterCustomer, setFilterCustomer] = useState<string>("");
  const [filteredCustomers, setFilteredCustomers] = useState<ICustomer[]>([]); //copy of "items" list to show a filtered list
  const [pagedCustomers, setPagedCustomers] = useState<ICustomer[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(30);
  const [lastPage, setLastPage] = useState<number>(0);
  const [first, setFirst] = useState<boolean>(false);
  const [last, setLast] = useState<boolean>(false);
  const [showImportCustomer, setShowImportCustomer] = useState<boolean>(false);
  const [isLoadingCustomer, setLoadingCustomer] = useState<boolean>(false);
  const [customerCodeImport, setCustomerCodeImport] = useState<string>("");
  const [budgetIdValue, setBudgetIdValue] = useState<any>();
  const { firm } = useContext(AuthContext);

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();

  const navigate = useNavigate();

  const loadList = (page: number) => {
    setLoading(true);
    customerService
      .getAll()
      .then((response) => {
        let customers: ICustomer[] = response.data;
        // console.log(response.data)

        setItems(customers);

        customers = filterList(customers);
        setFilteredCustomers(customers);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadList(1);
  }, []);

  const filterList = (customers: ICustomer[]) => {
    if (filterCustomer != "") {
      customers = customers.filter((customer) => {
        return (
          customer.name?.toLowerCase().indexOf(filterCustomer?.toLowerCase()) >=
            0 ||
          customer.cnpj?.toLowerCase().indexOf(filterCustomer?.toLowerCase()) >=
            0 ||
          customer.email
            ?.toLowerCase()
            .indexOf(filterCustomer?.toLowerCase()) >= 0
        );
      });
      setPage(0);
    }

    return customers;
  };

  const paginate = () => {
    if (page === 0) {
      setFirst(true);
    } else {
      setFirst(false);
    }
    if (page === lastPage) {
      setLast(true);
    } else {
      setLast(false);
    }
    let lastpage = Math.trunc(filteredCustomers.length / pageSize);
    setLastPage(lastpage);
    let pagedItems = filteredCustomers.slice(
      1 * page * pageSize,
      1 * page * pageSize + pageSize
    );
    setPagedCustomers(pagedItems);
  };

  useEffect(() => {
    paginate();
  }, [filteredCustomers, page, pageSize]);

  const showBudgets = (customer: ICustomer) => {
    navigate("/budget-list", {
      state: {
        customer: customer,
      },
    });
  };

  const handleNew = (customer: ICustomer) => {
    navigate("/product-list", {
      state: {
        customer: customer,
      },
    });
  };

  const importCustomer = () => {
    if (firm) {
      setLoadingCustomer(true);
      customerService
        .import(customerCodeImport, firm.id)
        .then((response: any) => {
          alert(response.data?.message);
          setLoadingCustomer(false);
          setCustomerCodeImport("");
          setShowImportCustomer(false);
          loadList(1);
        })
        .catch((error: any) => {
          console.log(error);
          let message = "Houve um erro ao importar o cliente. Tente novamente";
          if (error.response?.data?.message) {
            message = error.response?.data?.message;
          }
          alert(message);
          setLoadingCustomer(false);
        });
    } else {
      alert("Nenhuma empresa selecionada");
    }
  };

  const showDetailsCustomer = (customer: ICustomer) => {
    setSelectedCustomer(customer);
    setShowDetails(true);
  };

  const submitHandler = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    let budgetId: number = Number(budgetIdValue);
    if (firm){
      const customerId = budgetService
        .getCustomerByBudgetAndFirm(budgetId, firm.id)
        .then((result) => {
          let customer: ICustomer[] = items.filter((e) => e.id === result.data);
          setFilteredCustomers(customer);
        })
        .catch((error) => {
          alert("Orçamento não encontrado nesta empresa!");
          console.log(error);
        });
    }else{
      alert("Houve um erro. Selecione a empresa novamente.")
    }
  };

  const budgetChangeHandler = (event: { target: { value: any } }): void => {
    setBudgetIdValue(event.target.value);
  };

  useEffect(() => {
    let customers = items;
    customers = filterList(customers);
    setFilteredCustomers(customers);
  }, [filterCustomer]);

  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Clientes</h3>
            </div>
            <div id="container-filters">
              <div className="top-bar-collor">
                <div className="top-text">
                  <div className="mb-3">
                    <Form.Label>Buscar por cliente</Form.Label>
                    <FormControl
                      type="text"
                      placeholder="Procurar por Nome, CNPJ ou E-mail"
                      value={filterCustomer}
                      onChange={(e: any) => {
                        setFilterCustomer(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="top-bar-collor">
                <div className="top-text">
                  <Form
                    className="formSearchCustomerByBudget"
                    onSubmit={submitHandler}
                  >
                    <Form.Group className="mb-3" controlId="formBudgetId">
                      <Form.Label>Buscar por orçamento</Form.Label>
                      <Form.Control
                        name="budgetField"
                        id="budgetField"
                        type="text"
                        placeholder="Digite um número inteiro do orçamento"
                        onChange={budgetChangeHandler}
                      ></Form.Control>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Buscar
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={() => setPage(0)}
              >
                Início
              </button>
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={() => setPage(page - 1)}
                disabled={first}
              >
                <ArrowLeft />
              </button>
              {lastPage > 4 && page === lastPage && (
                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => setPage(page - 4)}
                >
                  {page - 3}
                </button>
              )}
              {lastPage > 3 && page >= lastPage - 1 && (
                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => setPage(page - 3)}
                >
                  {page - 2}
                </button>
              )}
              {lastPage > 2 && page >= 2 && (
                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => setPage(page - 2)}
                >
                  {page - 1}
                </button>
              )}
              {lastPage > 1 && page >= 1 && (
                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => setPage(page - 1)}
                >
                  {page}
                </button>
              )}
              <button
                className="btn btn-secondary btn-sm active"
                aria-pressed="true"
              >
                {page + 1}
              </button>
              {lastPage > 1 && page < lastPage && (
                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => setPage(page + 1)}
                >
                  {page + 2}
                </button>
              )}
              {lastPage > 2 && page < lastPage - 1 && (
                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => setPage(page + 2)}
                >
                  {page + 3}
                </button>
              )}
              {lastPage > 3 && page <= 1 && (
                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => setPage(page + 3)}
                >
                  {page + 4}
                </button>
              )}
              {lastPage > 4 && page === 0 && (
                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => setPage(page + 4)}
                >
                  {page + 5}
                </button>
              )}
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={() => setPage(page + 1)}
                disabled={last}
              >
                <ArrowRight />
              </button>
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={() => {
                  if (lastPage) setPage(lastPage);
                }}
              >
                Fim
              </button>
            </div>
            <div className="d-flex justify-content-end input-group input-group-sm mb-3 page-size-div">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Clientes por página:
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                aria-describedby="basic-addon1"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              />
            </div>
            <div>
              <button
                onClick={(e) => setShowImportCustomer(true)}
                className="btn btn-primary"
              >
                Importar cliente do Alvo
              </button>
            </div>
          </div>
          <br />
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>Código</th>
                <th>Nome</th>
                <th>CNPJ</th>
                <th>IE</th>
                <th>Cidade</th>
                <th>Estado</th>
                <th>E-mail</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {pagedCustomers.map((customer: ICustomer) => (
                <tr key={customer.id}>
                  <td>
                    <span>{customer.code}</span>
                  </td>
                  <td>
                    <span>{customer.name}</span>
                  </td>
                  <td>
                    <span>
                      <PatternFormat
                        format="##.###.###/####-##"
                        displayType="text"
                        value={customer.cnpj}
                        allowEmptyFormatting
                        mask="_"
                      />
                    </span>
                  </td>
                  <td>
                    <span>{customer.ie}</span>
                  </td>
                  <td>
                    <span>{customer.city}</span>
                  </td>
                  <td>
                    <span>{customer.state}</span>
                  </td>
                  <td>
                    <span>{customer.email}</span>
                  </td>
                  <td>
                    <button
                      onClick={(e) => showDetailsCustomer(customer)}
                      className="btn btn-primary"
                      style={{ marginRight: 5 }}
                    >
                      <InfoCircle />
                    </button>
                    <button
                      onClick={(e) => handleNew(customer)}
                      className="btn btn-primary"
                      title="Novo orçamento"
                    >
                      <PlusLg />
                    </button>
                    <a
                      href="javascript: void(0)"
                      onClick={(e) => showBudgets(customer)}
                      className="btn btn-secondary m-1"
                      title="Ver orçamentos"
                    >
                      <Search />
                    </a>
                  </td>
                </tr>
              ))}
              {filteredCustomers.length == 0 && !isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Nenhum produto encontrado.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal
        show={showImportCustomer}
        onHide={() => setShowImportCustomer(false)}
        className="modal-small"
      >
        <Modal.Header closeButton>
          <Modal.Title>Importação de Cliente do Alvo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                Código da Entidade
              </Form.Label>
              {customerCodeImport ? (
                <Form.Control
                  maxLength={30}
                  type="text"
                  placeholder="código da entidade"
                  value={customerCodeImport}
                  onChange={(e) => setCustomerCodeImport(e.target.value)}
                />
              ) : (
                <Form.Control
                  maxLength={30}
                  type="text"
                  placeholder="código da entidade"
                  onChange={(e) => setCustomerCodeImport(e.target.value)}
                />
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          { isLoadingCustomer && <p>Carregando...</p> }

          { !isLoadingCustomer && 
          <div>
            <Button
              variant="secondary"
              onClick={() => setShowImportCustomer(false)}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              className="btn btn-primary"
              onClick={(e) => importCustomer()}
            >
              Importar
            </Button>
          </div>
          }
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDetails}
        onHide={() => setShowDetails(false)}
        className="modal-large"
      >
        {selectedCustomer && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{selectedCustomer.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <strong>Código: </strong>
                <span>{selectedCustomer.customerscode}</span>
              </div>
              <div>
                <strong>Nome Fantasia: </strong>
                <span>{selectedCustomer.name}</span>
              </div>
              <div>
                <strong>CNPJ: </strong>
                <span>{selectedCustomer.cnpj}</span>
              </div>
              <div>
                <strong>Inscrição Estadual: </strong>
                <span>{selectedCustomer.ie}</span>
              </div>
              <div>
                <strong>E-mail: </strong>
                <span>{selectedCustomer.email}</span>
              </div>
              <div>
                <strong>Cidade: </strong>
                <span>
                  {selectedCustomer.city} / {selectedCustomer.state}
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDetails(false)}>
                Fechar
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default CustomerList;
