import api from "./api";

class FirmService {
  async getAllByItem(id_item: number): Promise<any> {
    return api
      .get(`/comment/find-all-item/${id_item}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getAllByBudget(id_budget: number): Promise<any> {
    return api
      .get(`/comment/find-all-budget/${id_budget}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async create(data: {
    text: string;
    id_entity: number;
    type: "item" | "budget";
  }): Promise<any> {
    return api({
      url: "/comment/create",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async delete(id: any): Promise<any> {
    return api
      .delete(`/firm/delete/${id}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const firmService = new FirmService();
export default firmService;
