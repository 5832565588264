import { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Table,
  Toast,
  Row,
  Col,
  ToastContainer,
} from "react-bootstrap";
import api from "../../services/api";
import {
  BsFillExclamationCircleFill,
  BsFillCaretUpFill,
  BsFillCaretDownFill,
} from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import moment from "moment";
import productService from "../../services/ProductService";
import UtilDate from "../../utils/util.date";
import IImportData from "../../interfaces/IImportData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import IStore from "../../interfaces/IStore";
import ISortType from "../../interfaces/ISortType";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ICustomer from "../../interfaces/ICustomer";
import IBudget from "../../interfaces/IBudget";
import IItemBudget from "../../interfaces/IItemBudget";
import { StatusItemBudget } from "../../enums/StatusItemBudget";
import { StatusBudget } from "../../enums/StatusBudget";
import budgetService from "../../services/BudgetService";
import AuthContext from "../../context/auth";
import { Trash, Files } from "react-bootstrap-icons";
registerLocale("pt-BR", ptBR);

const BudgetList = (props: any) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const [customer, setCustomer] = useState<ICustomer>(location.state?.customer);
  const [filter, setFilter] = useState<string>("");
  const [items, setItems] = useState<IBudget[]>([]);
  const [filteredItems, setFilteredItems] = useState<IBudget[]>([]);
  const navigate = useNavigate();
  const { firm } = useContext(AuthContext);
  const [isDeletingFromAlvo, setIsDeletingFromAlvo] = useState<boolean>(false);

  const loadList = (page: number) => {
    setLoading(true);
    budgetService
      .getAllByCustomerAndFirm(customer.id, firm?.id)
      .then((response) => {
        let budgets: IBudget[] = response.data;
        setItems(budgets);

        budgets = filterList(budgets);
        setFilteredItems(budgets);

        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(false);
    setFilteredItems(items);
    loadList(1);
  }, []);

  const filterList = (items: IBudget[]) => {
    if (filter !== "") {
      items = items.filter((item) => {
        return (
          item.id.toString()?.toLowerCase().indexOf(filter.toLowerCase()) >=
            0 ||
          item.description?.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          item.identifierAlvo?.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        );
      });
    }

    return items;
  };

  const denyBudget = (id: number) => {
    return budgetService.DenyBudget(id).then(() => {
      loadList(1);
    });
  };

  useEffect(() => {
    let itemsBudget = items;
    itemsBudget = filterList(itemsBudget);
    setFilteredItems(itemsBudget);
  }, [filter]);

  const showItems = (budget: IBudget, customer: ICustomer) => {
    // console.log('id do orçamento', budget)
    navigate("/pre-budget", {
      state: {
        budget: budget,
        customer: customer,
      },
    });
  };

  const copyWholeBudget = async (budget: IBudget) => {
    let budgetId: number = budget.id;

    budgetService
      .copyWholeBudget(budgetId)
      .then((result) => {
        loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const copyBudgetWithProcessOnly = (budget: IBudget) => {
    let budgetId: number = budget.id;

    budgetService
      .copyBudgetWithProcessesOnly(budgetId)
      .then((result) => {
        loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNew = (customer: ICustomer) => {
    navigate("/product-list", {
      state: {
        customer: customer,
      },
    });
  };

  const handleDelete = (e: React.FormEvent, item: IBudget) => {
    if (window.confirm(
      "Essa ação irá excluir o orçamento no Sistema de Orçamentos! Deseja continuar?"
    )) {
      budgetService
        .remove(item.id)
        .then(() => {
          loadList(1);
        })
        .catch((error: any) => {
          alert("Não foi possível excluir o orçamento");
        });
    }
  };

  const removeOnAlvo = (budgetId: number) => {
    if (window.confirm("Esse orçamento já foi enviado e será excluído somente no Alvo. Deseja continuar?")) {
      setIsDeletingFromAlvo(true);
      return budgetService
        .RemoveBudgetFromAlvo(budgetId)
        .then(() => {
          loadList(1)
          setIsDeletingFromAlvo(false);
        })
        .catch((error) => {
          setIsDeletingFromAlvo(false);
          console.log(error);
          alert(error?.response?.data?.message);
        });
    }
  };

  const removeDefinitively = (budgetId: number) => {
    if (window.confirm("Esse orçamento já foi enviado. Deseja excluir no Alvo e no Sistema de Orçamentos?")) {
      setIsDeletingFromAlvo(true);
      budgetService
        .RemoveBudgetFromAlvo(budgetId)
        .then(() => {
          //depois de excluir no alvo, exclui também no sistema de orçamentos
          budgetService
          .removeWithoutAlvo(budgetId)
          .then(() => {
            loadList(1);
            setIsDeletingFromAlvo(false);
          })
          .catch((error: any) => {
            setIsDeletingFromAlvo(false);
            alert("Não foi possível excluir o orçamento");
          });
        })
        .catch((error) => {
          //mesmo se não conseguir excluir no alvo, exclui também no sistema de orçamentos
          budgetService
          .removeWithoutAlvo(budgetId)
          .then(() => {
            loadList(1);
            setIsDeletingFromAlvo(false);
          })
          .catch((error: any) => {
            console.log(error)
            setIsDeletingFromAlvo(false);
            alert("Não foi possível excluir o orçamento");
          });
        });
    }
  };

  const getQuantity = (item: IBudget) => {
    let filteredItems: IItemBudget[];
    if (item.status === StatusBudget.SENT) {
      const itemBudget = item.items.find(
        (item) => item.status === StatusItemBudget.SENT
      );
      filteredItems = item.items.filter(
        (item) => item.version === itemBudget?.version
      );
    } else {
      const splitVersions = item.availableVersions.split(",").reverse();
      console.log("test", item.items, splitVersions[0]);
      filteredItems = item.items.filter(
        (item) => Number(item.version).toFixed(1) === splitVersions[0]
      );
    }
    return filteredItems.length;
  };

  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <Link
            className="btn btn-secondary mt-2"
            to={{
              pathname: "/customer-list",
            }}
          >
            Voltar para lista de clientes
          </Link>
          <div className="top-bar">
            <div className="title">
              <h3>Orçamentos</h3>
              <h4>{customer.name}</h4>
            </div>
            <div id="container-filters">
              <div className="top-bar-collor">
                <div className="top-text">
                  <h6>Buscar orçamento</h6>
                  <input
                    type="text"
                    value={filter}
                    onChange={(e: any) => setFilter(e.target.value)}
                    placeholder="Procure pelo código"
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={(e) => handleNew(customer)}
            className="btn btn-primary mt-2 mx-2 mb-2"
          >
            Novo orçamento
          </button>
          <br />
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>Código</th>
                <th># Itens</th>
                <th>Criado em</th>
                <th>Enviado em</th>
                <th>Código Alvo</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {filteredItems.map((item: IBudget) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.id}</span>
                  </td>
                  <td>
                    <span>{getQuantity(item)}</span>
                  </td>
                  <td>
                    <span>{UtilDate.stringToDMYHM(item.created_at)}</span>
                  </td>
                  <td>
                    <span>{UtilDate.stringToDMYHM(item.sent_at)}</span>
                  </td>
                  <td>
                    <span>{item.identifierAlvo}</span>
                  </td>
                  <td>
                    {item.status == StatusBudget.PRE_BUDGET && (
                      <span className="status-pending"> Orçamento </span>
                    )}
                    {item.status == StatusBudget.DRAFT && (
                      <span className="status-draft"> Rascunho </span>
                    )}
                    {item.status == StatusBudget.SENT && (
                      <span className="status-confirmed"> Enviado </span>
                    )}
                    {item.status == StatusBudget.DENIED && (
                      <span className="status-denied"> Declinado </span>
                    )}
                  </td>
                  <td>
                    <a
                      href="javascript: void(0)"
                      onClick={(e) => showItems(item, customer)}
                      className="btn btn-primary button_spacing"
                    >
                      Itens
                    </a>
                    {item.status !== StatusBudget.DENIED && (
                      <>
                        <button
                          className="btn btn-primary button_spacing"
                          onClick={(e) => copyWholeBudget(item)}
                        >
                          <Files />
                        </button>
                        <button
                          className="btn btn-primary button_spacing"
                          onClick={(e) => copyBudgetWithProcessOnly(item)}
                        >
                          <Files />
                          Sem Materiais
                        </button>
                      </>
                    )}
                    {item.status !== StatusBudget.SENT &&
                      item.status !== StatusBudget.DENIED && (
                        <Button
                          className="btn btn-warning button_spacing"
                          onClick={() => {
                            denyBudget(item.id);
                          }}
                        >
                          Declinar
                        </Button>
                      )}

                    {(item.status !== StatusBudget.DENIED && item.status !== StatusBudget.SENT) && (
                      <button
                        className="btn btn-danger button_spacing"
                        onClick={(e) => handleDelete(e, item)}
                        disabled={isDeletingFromAlvo}
                      >
                        Excluir
                      </button>
                    )}

                    {item.status === StatusBudget.SENT && (
                      <>
                      <button
                        className="btn btn-danger button_spacing"
                        onClick={(e) => removeOnAlvo(item.id)}
                        disabled={isDeletingFromAlvo}
                      >
                        Excluir no Alvo
                      </button>
                      
                      <button
                        className="btn btn-danger button_spacing"
                        onClick={(e) => removeDefinitively(item.id)}
                        disabled={isDeletingFromAlvo}
                      >
                        Exclusão total
                      </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}

              {filteredItems.length == 0 && !isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>
                      Nenhum orçamento para este cliente na empresa selecionada.
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default BudgetList;
