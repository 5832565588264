import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Eye, PostageFill } from "react-bootstrap-icons";
import { RegisterType } from "../../enums/TypeRegister";
import ILog from "../../interfaces/ILog";
import IRegister from "../../interfaces/IRegister";
import registerService from "../../services/registerService";
import UtilDate from "../../utils/util.date";

const LogModal = (props: ILog) => {
  const [showLog, setShowLog] = useState<boolean>(false);
  const [registers, setRegisters] = useState<IRegister[]>([]);

  const loader = () => {
    registerService.getAll(props.id_budget).then((result) => {
      setRegisters(result.data);
    });
  };

  useEffect(() => {
    loader();
  }, []);

  const handleClose = () => {
    setShowLog(false);
  };
  if (registers.length > 0) {
    return (
      <div>
        <Button
          variant="warning"
          onClick={() => setShowLog(true)}
          title="mostrar"
        >
          <Eye />
        </Button>
        <Modal show={showLog} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Lista de alterações</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h5>Selecione os produtos para adicionar ao orçamento</h5>
              <Table striped bordered hover>
                <thead>
                  <tr className="tr">
                    <th>Usuário</th>
                    <th>Tipo</th>
                    <th>Item</th>
                    <th>Data/Hora</th>
                  </tr>
                </thead>
                <tbody>
                  {registers.map((item: IRegister) => (
                    <tr key={item.id}>
                      <td>
                        <span>{item.user.username}</span>
                      </td>
                      <td>
                        {item.type == RegisterType.CREATED && (
                          <span> Criou </span>
                        )}
                        {item.type == RegisterType.DELETED && (
                          <span> Deletou </span>
                        )}
                        {item.type == RegisterType.UPDATED && (
                          <span> Atualizou </span>
                        )}
                      </td>
                      {item.materialName && (
                        <td>
                          <span>{"Material: " + item.materialName}</span>
                        </td>
                      )}
                      {item.processName && (
                        <td>
                          <span>{"Processo: " + item.processName}</span>
                        </td>
                      )}
                      {!item.materialName && !item.processName && (
                        <td>
                          <span>{"Item " + props.id_budget}</span>
                        </td>
                      )}
                      <td>
                        <span>
                          {UtilDate.stringToDMYHM(
                            item.modification_date.toString()
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}

                  {registers.length == 0 && (
                    <tr>
                      <td colSpan={6}>
                        <p>Nenhum registro encontrado.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default LogModal;
