import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss"
import { Button, Modal, Form, FormGroup } from "react-bootstrap";

const RegisterCustomer = () => {

    const [customerName, setCustomerName] = useState<string>("");
    const [tradeName, setTradeName] = useState<string>("");
    const [rgie, setRgIe] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>();
    const [cnpj, setCnpj] = useState<string>("");
    const [cep, setCep] = useState("");
    const [people,setPeople]=useState("");

    const [address, setAddress] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [number, setNumber] = useState("");

    const [ddi, setDdi] = useState("");
    const [ddd, setDdd] = useState("");
    const [phone,setPhone]= useState("")
    const [email, setEmail] = useState("");
    
    let errors=[];

    const validate =():boolean=>{
        if(customerName.trim().indexOf(" ")==-1){
            errors.push("Preencha o nome completo")
        }
        if(/\d/.test(city)){
            errors.push("Cidade precisa de numero")
        }

        return false;
    }
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        validate();
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="container-fluid main-container">
                    <div className="title">
                        <h1>Cadastro de Cliente</h1>
                    </div>
                    <div>
                        <div>
                            <h2>Identificação do cliente</h2>
                        </div>
                        <div className="form-id-customer">
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="inputNameCustomer">Nome do cliente</Form.Label>
                                <Form.Control type="text" id="inputNameCustomer" onChange={(e)=>{setCustomerName(e.target.value)}}/>
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">Nome fantasia</Form.Label>
                                <Form.Control type="text" id="" onChange={(e)=>{setTradeName(e.target.value)}}/>
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">RG/IE</Form.Label>
                                <Form.Control type="text" id="" />
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">Data de nascimento</Form.Label>
                                <Form.Control type="date" id="" onChange={(e)=>{setBirthDate(e.target.value)}}/>
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">CPF/CNPJ</Form.Label>
                                <Form.Control type="text" id="" onChange={(e)=>{setCnpj(e.target.value)}}/>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label htmlFor="">Tipo de pessoa</Form.Label>
                                <Form.Check
                                    value="Física"
                                    type="radio"
                                    aria-label="radio 1"
                                    label="Pessoa física"
                                    name="pessoa"
                                    onChange={(e)=>{setPeople(e.target.value)}}
                                />
                                <Form.Check
                                    value="Jurídica"
                                    type="radio"
                                    aria-label="radio 2"
                                    label="Pessoa júridica"
                                    name="pessoa"
                                    onChange={(e)=>{setPeople(e.target.value)}}
                                />
                            </FormGroup>
                        </div>

                    </div>
                    <div>
                        <div>
                            <h2>Endereço do cliente</h2>
                        </div>
                        <div className="form-id-customer">
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">CEP</Form.Label>
                                <Form.Control type="text" id=""  onChange={(e)=>{setCep(e.target.value)}} />
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">Endereço</Form.Label>
                                <Form.Control type="text" id="" onChange={(e)=>{setAddress(e.target.value)}} />
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">Numero</Form.Label>
                                <Form.Control type="number" id="" onChange={(e)=>{setNumber(e.target.value)}} />
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">Bairro</Form.Label>
                                <Form.Control type="text" id="" onChange={(e)=>{setNeighborhood(e.target.value)}} />
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">Cidade</Form.Label>
                                <Form.Control type="text" id="" onChange={(e)=>{setCity(e.target.value)}}  />
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">País</Form.Label>
                                <Form.Control type="text" id="" onChange={(e)=>{setCountry(e.target.value)}}  />
                            </FormGroup>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2>Comunicação com o cliente</h2>
                        </div>
                        <div className="form-id-customer">
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="inputNameCustomer">DDI</Form.Label>
                                <Form.Control type="text" id="inputNameCustomer" onChange={(e)=>{setDdi(e.target.value)}} />
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">DDD</Form.Label>
                                <Form.Control type="text" id="" onChange={(e)=>{setDdd(e.target.value)}} />
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">Telefone</Form.Label>
                                <Form.Control type="tel" id="" onChange={(e)=>{setPhone(e.target.value)}} />
                            </FormGroup>
                            <FormGroup className="inputGroup">
                                <Form.Label htmlFor="">E-mail</Form.Label>
                                <Form.Control type="email" id="" onChange={(e)=>{setEmail(e.target.value)}} />
                            </FormGroup>
                        </div>

                    </div>
                    <div className="button-footer">
                        <Button type="submit" className="register-button">Cadastrar</Button>
                    </div>
                </div>
            </Form>

        </>
    );

}

export default RegisterCustomer;