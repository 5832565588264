import IItemBudget from "../interfaces/IItemBudget";
import api from "./api";

class BudgetService {
  async createPreBudget(
    productsIds: Number[],
    customerId: Number,
    firmId: number
  ): Promise<any> {
    return api
      .post("/budgets/pre-budget", {
        productsIds,
        companyId: 1,
        customerId: customerId,
        firmId: firmId,
      })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async createItemBudget(data: any): Promise<any> {
    return api({
      url: "/budgets/create/item-budget",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async copyItemBudget(data: any): Promise<any> {
    return api({
      url: "/budgets/copy/item-budget",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async sendBudget(id: number, version: number): Promise<any> {
    return api({
      url: "/budgets/send-budget/" + id + "?version=" + version.toFixed(1),
      method: "PUT",
      timeout: 150000,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async DenyBudget(id: number): Promise<any> {
    return api({
      url: "/budgets/deny/" + id,
      method: "PATCH",
      timeout: 5000,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async DenyItem(id: number): Promise<any> {
    return api({
      url: "/budgets/deny-item/" + id,
      method: "PATCH",
      timeout: 5000,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async RemoveBudgetFromAlvo(id: number): Promise<any> {
    return api({
      url: "/budgets/remove-from-alvo/" + id,
      method: "PATCH",
      timeout: 5000,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async addProductsToBudget(
    id: number,
    productsIds: number[],
    version: number
  ): Promise<any> {
    return api({
      url: "/budgets/add-products/" + id,
      data: {
        productIds: productsIds,
        version: version,
      },
      method: "POST",
      timeout: 150000,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async copyWholeBudget(id: number): Promise<any> {
    return api({
      url: "budgets/copy-whole-budget/" + id,
      method: "POST",
      timeout: 150000,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async copyBudgetWithProcessesOnly(id: number): Promise<any> {
    return api({
      url: "budgets/copy-budget-with-processes-only/" + id,
      method: "POST",
      timeout: 150000,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async turnBudgetToPreBudget(id: number): Promise<any> {
    return api({
      url: "/turn-to-pre-budget/" + id,
      method: "POST",
      timeout: 150000,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async completeItemBudget(itemId: Number, data: any): Promise<any> {
    return api({
      url: `/budgets/item/complete/${itemId}`,
      method: "PUT",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getAllByCustomerAndFirm(
    customerId: number,
    firmId: number | undefined
  ): Promise<any> {
    return api
      .get(
        "/budgets/all-by-customer?customer=" + customerId + "&firm=" + firmId
      )
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getById(id: number): Promise<any> {
    return api
      .get("/budgets/get-by-id/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async checkIfExists(
    id: number,
    name: string,
    alt: string,
    itemId: number,
    version: string
  ): Promise<boolean> {
    return api
      .get<boolean>(
        `/budgets/check-if-exists?id=${id}&item=${itemId}&name=${name}&alt=${alt}&version=${version}`
      )
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async createNewVersion(id: number, version: string): Promise<any> {
    return api
      .put(`/budgets/create-new-version/${id}?version=${version}`)
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getProcessesByItem(processId: number): Promise<any> {
    return api
      .get("/budgets/process-by-item?item=" + processId)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getRawMaterialsById(materialsId: number): Promise<any> {
    return api
      .get("/budgets/raw-materials-by-item?item=" + materialsId)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getRawMaterialsAndProcesses(budgetId: number): Promise<any> {
    return api
      .get("/budgets/get-material-and-process/" + budgetId)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getItemBudget(id: number): Promise<any> {
    return api
      .get("/budgets/item-budget/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getCustomerByBudget(id: number): Promise<any> {
    return api
      .get("/budgets/search-customer-by-budget/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getCustomerByBudgetAndFirm(id: number, firm: number): Promise<any> {
    return api
      .get("/budgets/search-customer-by-budget-firm/" + firm + "/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async removeItem(id: number): Promise<any> {
    return api
      .delete("/budgets/delete/item/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async remove(id: number): Promise<any> {
    return api
      .delete("/budgets/delete/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async removeWithoutAlvo(id: number): Promise<any> {
    return api
      .delete("/budgets/delete-without-alvo/" + id)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const budgetService = new BudgetService();
export default budgetService;
