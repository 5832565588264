import api from "./api"

class FeeService{
    async getActive(): Promise<any>{   
        return api.get("/fee/find-actives").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getAll(state: any): Promise<any>{   
        return api.get(`/fee/find-active?state=${state}`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async create(data: any): Promise<any>{
        return api({
            url: "/fee/create",
            method: "POST",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async update(data: any, id: any): Promise<any>{
        return api({
            url: `/fee/update/${id}`,
            method: "PUT",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async delete(id: any): Promise<any>{   
        return api.delete(`/fee/delete/${id}`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    
    
}

const feeService = new FeeService()
export default feeService

