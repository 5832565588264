
import { useContext, useEffect, useState } from "react";
import { Button, Modal, Form, Table, Toast, Row, Col, ToastContainer } from "react-bootstrap";
import api from "../../services/api";
import { BsFillExclamationCircleFill, BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss"
import moment from 'moment';
import UtilDate from "../../utils/util.date";
import IImportData from "../../interfaces/IImportData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import IStore from "../../interfaces/IStore";
import ISortType from "../../interfaces/ISortType";
import customerService from "../../services/CustomerService";
import { Link, useNavigate } from "react-router-dom";
import ICustomer from "../../interfaces/ICustomer";
import { PatternFormat } from 'react-number-format';
import IFirm from "../../interfaces/IFirm";
import firmService from "../../services/FirmService";
import AuthContext from "../../context/auth";
import { UserType } from "../../enums/UserType";

registerLocale('pt-BR', ptBR)

const FirmList = () => {

  const [items, setItems] = useState<IFirm[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const [filterFirm, setFilterFirm] = useState<string>("")
  const [filteredFirm, setFilteredFirm] = useState<IFirm[]>([]) //copy of "items" list to show a filtered list
  const [firmId, setFirmId] = useState<number>()
  const [cnpj, setCnpj] = useState<string>()
  const [name, setName] = useState<string>()
  const [code, setCode] = useState<string>()
  const [show, setShow] = useState<boolean>(false)
  const [type, setUserType] = useState<string | null>(window.sessionStorage.getItem("type"))
  const navigate = useNavigate()

  const { firm,  selectFirm } = useContext(AuthContext);

  const loadList = (page: number) => {
    setLoading(true)
    firmService.getAll().then((response) => {
      let firm: IFirm[] = response.data

      setItems(firm)

      firm = filterList(firm)
      setFilteredFirm(firm)

      setLoading(false)
    }).catch((error) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    loadList(1)
  }, [])

  const filterList = (firms: IFirm[]) => {

    if (filterFirm != "") {
      firms = firms.filter((firm) => {
        return (firm.name.toLowerCase().indexOf(filterFirm.toLowerCase()) >= 0)
          || (firm.cnpj.toLowerCase().indexOf(filterFirm.toLowerCase()) >= 0)
          || (firm.code.toLowerCase().indexOf(filterFirm.toLowerCase()) >= 0)
      })
    }

    return firms
  }

  useEffect(() => {
    let customers = items
    customers = filterList(customers)
    setFilteredFirm(customers)
  }, [filterFirm])

  const handleShowUpdate = (firm: IFirm) => {
    setCode(firm.code)
    setCnpj(firm.cnpj)
    setName(firm.name)
    setFirmId(firm.id)
    handleShow()
  }

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    let data = {
      code: code,
      cnpj: cnpj,
      name: name,
    }
        if(firmId){
            firmService.update(data, firmId)
          .then((response) => {
            loadList(1)
            handleClose()
          })
        }else{
            firmService.create(data)
          .then((response) => {
            loadList(1)
            handleClose()
          })
        }
  }

  const handleDelete = async (id: number) => {
    await firmService.delete(id)
    loadList(1)
  }
  

  const handleClose = () => {
    setCnpj(undefined)
    setCode(undefined)
    setName(undefined)
    setFirmId(undefined)
    setShow(false)
  };
  const handleShow = () => setShow(true);

  const onSelectFirm = (firm: IFirm) => {
    window.sessionStorage.setItem("firmId", firm.id.toString());
    window.sessionStorage.setItem("firmName", firm.name);
    selectFirm(firm)
    navigate('/customer-list')
  }

  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Empresas</h3>
            </div>
            
            <div id="container-filters">
              <div className="top-bar-collor">
              </div>
            </div>
          </div>
          {type === UserType.ADMIN &&
            <div className="d-inline-flex">
              <Button className="btn btn-primary m-2" onClick={handleShow} >Adicionar empresa</Button>
            </div>}

          <br />
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>
                  Código
                </th>
                <th>
                  Nome
                </th>
                <th>
                  CNPJ
                </th>
                <th>
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading &&
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              }
              {
                filteredFirm.map((firm: IFirm) => (
                  <tr key={firm.id}>
                    <td><span>{firm.code}</span></td>
                    <td><span>{firm.name}</span></td>
                    <td>
                      <span>
                        <PatternFormat format="##.###.###/####-##" displayType="text" value={firm.cnpj} allowEmptyFormatting mask="_" />
                        </span></td>
                    <td>
                      {type === UserType.ADMIN &&
                        <>
                        <button className="btn btn-danger" style={{marginRight: "20px"}} onClick={e => handleDelete(firm.id)}>
                          Deletar
                        </button>
                        <button className="btn btn-primary" style={{marginRight: "4px"}} onClick={e => handleShowUpdate(firm)}>
                          Atualizar
                        </button>
                        </>}
                        <button className="btn btn-success" onClick={e => onSelectFirm(firm)}>
                          Selecionar
                        </button>
                    </td>
                  </tr>
                ))
              }
              {
                (filteredFirm.length == 0 && !isLoading) &&
                <tr>
                  <td colSpan={6}>
                    <p>Nenhuma empresa encontrada.</p>
                  </td>
                </tr>
              }

            </tbody>
          </Table>

        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Empresa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
                  <Form.Group className="mb-5">
                      <Form.Label htmlFor="inputIdCustomer">Código</Form.Label>
                      {firmId
                      ?<Form.Control type="text" placeholder="Código da firma" value={code} onChange={(e) => setCode(e.target.value)}/>
                      :<Form.Control type="text" placeholder="Código da firma" onChange={(e) => setCode(e.target.value)}/>
                      }
                      
                  </Form.Group>
                  <Form.Group className="mb-5">
                      <Form.Label htmlFor="inputNameCustomer">Nome</Form.Label>
                      {firmId
                      ?<Form.Control type="text" placeholder="Nome da firma" value={name} onChange={(e) => setName(e.target.value)}/>
                      :<Form.Control type="text" placeholder="Nome da firma" onChange={(e) => setName(e.target.value)}/>
                      }
                      
                  </Form.Group>
                  <Form.Group className="mb-5">
                      <Form.Label htmlFor="inputNameCustomer">CNPJ</Form.Label>
                      {firmId
                      ?<Form.Control type="text" placeholder="CNPJ da firm" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
                      :<Form.Control type="text" placeholder="CNPJ da firm" onChange={(e) => setCnpj(e.target.value)}/>
                      }
                      
                  </Form.Group>
                  
                </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" className="btn btn-primary" onClick={(e) => handleClick(e)}>
                        Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
};

export default FirmList;
