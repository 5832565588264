import React, { useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss"
import { Button, Modal, Form, FormGroup } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import icmsService from "../../services/IcmsService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IIcms from "../../interfaces/IIcms";
import { NumericFormat } from "react-number-format";

interface IState{
    name: string;
    alias: string;
}

const RegisterIcms = () => {

    const location = useLocation();
    const [state, setState] = useState<string>("");
    const [icms, setIcms] = useState<IIcms>(location.state?.tax);
    const [description, setDescription] = useState<string>(icms?.state_abbreviation)
    const [value, setValue] = useState<number | undefined>(icms?.quota);
    const navigate = useNavigate();
    

    const [states, setStates] = useState<IState[]>([
        {"name": "Acre", "alias": "AC"},
        {"name": "Alagoas", "alias": "AL"},
        {"name": "Amapá", "alias": "AP"},
        {"name": "Amazonas", "alias": "AM"},
        {"name": "Bahia", "alias": "BA"},
        {"name": "Ceará", "alias": "CE"},
        {"name": "Distrito Federal", "alias": "DF"},
        {"name": "Espírito Santo", "alias": "ES"},
        {"name": "Goiás", "alias": "GO"},
        {"name": "Maranhão", "alias": "MA"},
        {"name": "Mato Grosso", "alias": "MT"},
        {"name": "Mato Grosso do Sul", "alias": "MS"},
        {"name": "Minas Gerais", "alias": "MG"},
        {"name": "Pará", "alias": "PA"},
        {"name": "Paraíba", "alias": "PB"},
        {"name": "Paraná", "alias": "PR"},
        {"name": "Pernambuco", "alias": "PE"},
        {"name": "Piauí", "alias": "PI"},
        {"name": "Rio de Janeiro", "alias": "RJ"},
        {"name": "Rio Grande do Norte", "alias": "RN"},
        {"name": "Rio Grande do Sul", "alias": "RS"},
        {"name": "Rondônia", "alias": "RO"},
        {"name": "Roraima", "alias": "RR"},
        {"name": "Santa Catarina", "alias": "SC"},
        {"name": "São Paulo", "alias": "SP"},
        {"name": "Sergipe", "alias": "SE"},
        {"name": "Tocantins", "alias": "TO"}
    ])

    const handleClick =(e:React.FormEvent)=>{
        e.preventDefault();
        let statename = states.find(s => s.alias === description)?.name;

        let data={
            state_abbreviation:description,
            state_name: statename,
            quota:value
        }
        if(icms){
            icmsService.update(data,icms.id)
            .then((response)=>{
                navigate("/icms-list");
            })
        }else{
            icmsService.create(data)
            .then((response)=>{
                navigate("/icms-list");
            })
        }
    }

    useEffect(() => {
        if(icms){
         setDescription(icms.state_name);
         setValue(icms.quota);
        }
     }, [])

    return (
        <div className="container-fluid container-main">
            <div>
                <h1>Cadastro de ICMS</h1>
            </div>
            <div className="div-form">
                <Form >                    
                    <Form.Group className="mb-5">
                        <Form.Label htmlFor="state">Estado</Form.Label>
                        <Form.Label htmlFor="inputStateName">Estado</Form.Label>
                      
                            <Form.Select id="state"  defaultValue={description} onChange={e => setDescription(e.target.value)}>
                            
                                <option value="">Selecione o estado</option>
                                {states.map((item) => (
                                    <option value={item.alias} >{item.name}</option>
                                ))
                                }
                                                            
                            </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-5">
                        <Form.Label htmlFor="icms">ICMS</Form.Label>
                        
                            <NumericFormat 
                                className="form-control"  
                                value={value}  
                                id="icms" 
                                placeholder="Valor do ICMS" 
                                decimalSeparator=","
                                thousandSeparator="."
                                type="text"
                                valueIsNumericString={true}
                                onValueChange={(values) => {
                                    setValue(values.floatValue)
                                }}
                              />
                    </Form.Group>
                    <Button className="button-send" onClick={(e) => handleClick(e)} >
                        Salvar
                    </Button>                    
                </Form>
            </div>
            <ToastContainer />
        </div>
    )
}

export default RegisterIcms;