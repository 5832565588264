import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Toast, Row, Col, ToastContainer } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { NumericFormat } from "react-number-format";


import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import "../../shared/global.scss"
import IPreProcess from "../../interfaces/IPreProcess";
import preProcessService from "../../services/PreProcessService";

import IMaterial from "../../interfaces/IMaterial";
import materialService from "../../services/MaterialService";


registerLocale('pt-BR', ptBR)


const MaterialList = () => {

    //const [list, setList] = useState<IPreProcess[]>([])
    const [list, setList] = useState<IMaterial[]>([])


    const [isLoading, setLoading] = useState<boolean>(true)
    const navigate = useNavigate();

    const loadList = () => {
        setLoading(true)
        materialService.getAll().then((response) => {
            let tax: IMaterial[] = response.data
            setList(tax)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        })

    }
    useEffect(() => {
        loadList()
    }, [])

    const handleClick = async (id: number) => {
        await materialService.delete(id)
        loadList()
    }

    const updateMaterial = (material: IMaterial) => {
        navigate("/register-material", {
            state: {
                material: material
            }
          })
    }


    return (
        <div className="container-fee">
            <div>
                <h1>
                    Matéria-Prima
                </h1>
            </div>
            <Link to="/register-material" className="button-top-left btn btn-primary m-2">Adicionar</Link>
            <div className="table">
                <Table striped >
                    <thead>
                        <tr>
                            <th>
                                Código
                            </th>
                            <th>
                                Descrição
                            </th>
                            <th>
                                Unidade de medida
                            </th>
                            <th>
                                Custo
                            </th>
                            <th>
                                Alíquota
                            </th>
                            <th>
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading &&
                            <tr>
                                <td colSpan={4}>
                                    <p>Carregando...</p>
                                </td>
                            </tr>
                        }
                        {
                            list.map((material:IMaterial)=>(
                                <tr key={material.id}>
                                    <td><span>{material.id}</span></td>
                                    <td><span>{material.description}</span></td>
                                    <td><span>{material.units_of_measurement}</span></td>
                                    <td>
                                        <span>
                                         <NumericFormat 
                                            value={material.cost}
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            type="text"
                                            displayType="text"
                                            valueIsNumericString={true}
                                          />
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <NumericFormat 
                                            value={material.aliquota}
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            type="text"
                                            displayType="text"
                                            valueIsNumericString={true}
                                          />
                                        </span>
                                    </td>
                                    <td> <Button
                                        onClick={() => updateMaterial(material)}
                                        className="btn btn-primary">Alterar</Button>
                                        <Button className="btn btn-danger" onClick={() => handleClick(material.id)}>Excluir</Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default MaterialList;